import React from 'react';
import InputField from '../ApplyForm/InputField';
import * as s from './WTRegistrationForm.module.scss';
import axios from 'axios';
import Text from '../Text';

export interface WTRegistrationFormProps {
  placeholders: {
    firstName: string;
    lastName: string;
    email: string;
    submit: string;
  };
  _sitemeta: Array<{
    url: string;
  }>;
  languages: DropdownItem[];
}

interface State {
  firstName: RegistrationFormInputField;
  lastName: RegistrationFormInputField;
  email: RegistrationFormInputField;
  language: DropdownItem;
  dropdownOpen: boolean;
  errorText: string;
}

interface RegistrationFormInputField {
  value: string;
  valid: boolean;
}

interface DropdownItem {
  language: string;
  redirectUrl: string;
}

class WTRegistrationForm extends React.Component<
  WTRegistrationFormProps,
  State
> {
  constructor(props: WTRegistrationFormProps) {
    super(props);
    this.state = {
      firstName: {
        value: '',
        valid: false,
      },
      lastName: {
        value: '',
        valid: false,
      },
      email: {
        value: '',
        valid: false,
      },
      language: null,
      dropdownOpen: false,
      errorText: '',
    };
  }

  validateEmail = (email: string) => {
    const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.length || !regexp.test(email)) {
      return false;
    }

    return true;
  };

  validateName = (input: string) => {
    if (input && input.length) {
      return true;
    }
    return false;
  };

  onSubmit = e => {
    const { firstName, lastName, email, language } = this.state;
    e.preventDefault();

    if (!(firstName.valid && lastName.valid && email.valid)) {
      return;
    }

    if (!language) {
      this.setState({
        errorText: 'Please select subtitles from the dropdown!',
      });
      return;
    }

    const data = new FormData();
    data.set('first_name', firstName.value);
    data.set('last_name', lastName.value);
    data.set('email', email.value);
    data.set('language', language.language);

    axios
      .post(
        this.props._sitemeta.url + '/wp-json/swace/v1/wt_registration_form',
        data
      )
      .then(() => {
        window.location.replace(language.redirectUrl);
      })
      .catch(error => {
        this.setState({ errorText: error.response.data.message });
      });
  };

  render() {
    const { languages, placeholders } = this.props;
    const {
      firstName,
      lastName,
      email,
      language,
      dropdownOpen,
      errorText,
    } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <div className="row">
          <InputField
            type="text"
            name="firstName"
            placeholder={placeholders.firstName}
            onChange={e => {
              const validName = this.validateName(e.target.value);
              this.setState({
                firstName: { value: e.target.value, valid: validName },
              });
            }}
            value={firstName.value}
            required
            customBootstrap="col-md col-12 mb-md-0 mb-2"
            className={s.inputField}
          />
          <InputField
            type="text"
            name="lastName"
            placeholder={placeholders.lastName}
            onChange={e => {
              const validName = this.validateName(e.target.value);
              this.setState({
                lastName: { value: e.target.value, valid: validName },
              });
            }}
            value={lastName.value}
            required
            customBootstrap="col-md col-12"
            className={s.inputField}
          />
          <InputField
            type="email"
            name="email"
            placeholder={placeholders.email}
            onChange={e => {
              const validEmail = this.validateEmail(e.target.value);
              this.setState({
                email: { value: e.target.value, valid: validEmail },
              });
            }}
            value={email.value}
            required
            customBootstrap="col-12 my-2"
            className={`${s.inputField}`}
          />
          <div className="col-12">
            <div className={`${s.dropdown} my-2`}>
              <div
                onClick={() => this.setState({ dropdownOpen: !dropdownOpen })}
                className={`${s.dropdownLabel} d-flex align-items-center justify-content-between px-3 py-2`}
              >
                <Text pSmall light>
                  {language ? language.language : 'Language subtitles'}
                </Text>
                <div
                  className={`${s.arrow} ${dropdownOpen ? s.open : ''}`}
                ></div>
              </div>
              {dropdownOpen && (
                <ul className={`py-2 ${s.dropdownList}`}>
                  {languages &&
                    languages.map((lang: DropdownItem, idx: number) => (
                      <li
                        key={`language_${idx}`}
                        className={'py-2 px-3'}
                        onClick={() =>
                          this.setState({
                            language: {
                              language: lang.language,
                              redirectUrl: lang.redirectUrl,
                            },
                            dropdownOpen: false,
                          })
                        }
                      >
                        {lang.language}
                      </li>
                    ))}
                </ul>
              )}
            </div>
            <input
              type="submit"
              value={placeholders.submit}
              className={`col-12 pb-3 pt-4 my-4 ${
                !(firstName.valid && lastName.valid && email.valid && language)
                  ? s.invalid
                  : ''
              }`}
            />
          </div>
        </div>
        {errorText && (
          <div className={s.errorText}>
            <Text pMedium>{errorText}</Text>
          </div>
        )}
      </form>
    );
  }
}

export default WTRegistrationForm;
