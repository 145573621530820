import * as React from 'react';
import * as s from './RadioButton.module.scss';

interface Props {
  currentId: string;
  onChange: (_: boolean) => any;
  radioButtonText: string;
  departmentId: string;
}

class RadioButton extends React.Component<Props> {
  render() {
    const { onChange, currentId, radioButtonText, departmentId } = this.props;
    return (
      <div className={`col-6 d-flex ${s.radiobutton}`}>
        <label className={s.container}>
          <input
            data-id={departmentId}
            type="radio"
            onChange={(e) => onChange(e.target.checked)}
            checked={currentId === departmentId}
          />
          <span className={s.checkmark} />
        </label>
        <div className={currentId === departmentId ? s.checkedtext : ''}>
          {radioButtonText}
        </div>
      </div>
    );
  }
}
export default RadioButton;
