import React from 'react';
import { graphql } from 'gatsby';
import PageTemplate from '../components/PageTemplate';
import Cookie from '../components/shared/Cookie';

const Page = ({ data }) => {
  const {
    page,
    parent,
    children,
    wpFormsChildren,
    siblings,
    allWpNewsItem: news,
    allJob: jobs,
    allDepartment: departments,
    // allInstaNode: instaPosts,
    allWpNewspost: newsposts,
    allWpOffice: offices,
    allWpPerson: employees,
  } = data;

  const siteMeta = data.wp.generalSettings;

  return (
      <PageTemplate
        page={page}
        parent={parent}
        children={children}
        wpFormsChildren={wpFormsChildren}
        siblings={siblings}
        offices={offices}
        news={news}
        employees={employees}
        jobs={jobs}
        departments={departments}
        siteMeta={siteMeta}
        // instaPosts={instaPosts}
        newsposts={newsposts}
      />
  );
};

export const pageQuery = graphql`
  query PageById($id: String!, $parentId: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageFragment
    }
    parent: wpPage(id: { eq: $parentId }) {
      wordpressId: id
      uri
      title
    }
    siblings: allWpPage(
      filter: { wpParent: { node: { id: { eq: $parentId, ne: null } } } }
      sort: { fields: menuOrder, order: ASC }
    ) {
      edges {
        node {
          wordpressId: id
          uri
          title
        }
      }
    }
    children: allWpPage(
      filter: { wpParent: { node: { id: { eq: $id } } } }
      sort: { fields: menuOrder, order: ASC }
    ) {
      edges {
        node {
          wordpressId: id
          uri
          title
        }
      }
    }
    wpFormsChildren: allWpPage(
      filter: { wpParent: { node: { id: { eq: $id } } } }
      sort: { fields: menuOrder, order: ASC }
    ) {
      edges {
        node {
          uri
          childrenWpForms: pageFields {
            page {
              ... on WpPage_Pagefields_Page_WpForms {
                fieldGroupName
                pageHeading
                pageSubheading
                gridFrameHeading
                gridIcon {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ...WordpressPageFragment
    ...WordpressSiteMetadataFragment
  }
`;

export default Page;
