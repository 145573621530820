import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { News as NewsType } from '../../types';
import * as s from './News.module.scss';
import Section from '../shared/Section';
import Text from '../shared/Text';
import Button from '../swace/button';
import Reveal from '../swace/reveal';
import Grid from '../shared/Grid';
import LinkGroup from '../shared/LinkGroup';

export interface Props {
  id: number;
  title: string;
  ingress: string;
  buttonText: string;
  buttonType: string;
  buttonLink: string;
  buttonAction: string;
  buttons: Array<{
    buttonText: string;
    buttonLinkType: string;
    button_link: string;
    button_link_external: string;
  }>;
  previewNumber: string;
  _news: NewsType;
}

class News extends React.Component<Props> {
  state = {
    previewNumber: this.props.previewNumber
      ? parseInt(this.props.previewNumber, 10)
      : Infinity,
  };

  categoryName = (c) => {
    switch (c) {
      case 'zound_news':
        return 'Zound News';
      case 'brand_news':
        return 'Brand News';
      case 'blog_post':
        return 'Blog Post';
      default:
        return 'News';
    }
  };

  showMore = (previewNumber) => {
    this.setState({
      previewNumber: previewNumber === Infinity ? Infinity : previewNumber + 3,
    });
  };

  render() {
    const {
      title,
      ingress,
      buttonText,
      buttonType,
      buttonLink,
      buttonAction,
      buttons,
      _news: news,
    } = this.props;

    const { previewNumber } = this.state;
    const numberOfNews = news && news.edges && news.edges.length;

    return (
      <Section>
        <div className={`row`}>
          <div className="col-12 col-md-10 offset-md-1">
            <Reveal drop>
              <Text h2>{title}</Text>
            </Reveal>
            <Reveal lift>
              <div className={!buttons ? s.items : ''}>
                <Text ingressMedium>{ingress}</Text>
              </div>
              {buttons && <LinkGroup buttons={buttons} />}
            </Reveal>
          </div>
        </div>
        <Grid
          items={news.edges
            .filter((n) => n.node.slug !== 'swace_news_placeholder')
            .slice(0, previewNumber)
            .map((n) => ({
              title: n.node.newsItem.title,
              image:
                n.node.newsItem.previewImage &&
                n.node.newsItem.previewImage.localFile.childImageSharp.fluid
                  .src,
              tags: [this.categoryName(n.node.newsItem.newsCategory)],
              href: `/news/${n.node.slug}`,
              key: n.node.id,
            }))}
        />
        <div className="row">
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 offset-sm-2 offset-md-3 offset-lg-4 d-flex justify-content-center">
            <Reveal lift>
              {buttonType === 'link' && (
                <Button href={buttonLink} className={s.button} filled>
                  {buttonText}
                </Button>
              )}
              {buttonType === 'action' && previewNumber <= numberOfNews && (
                <Button
                  className={s.button}
                  onClick={() => this.showMore(previewNumber)}
                  filled
                >
                  {buttonText}
                </Button>
              )}
            </Reveal>
          </div>
        </div>
      </Section>
    );
  }
}

export const query = graphql`
  fragment NewsBlockFragment on WpPage_Pagefields_Page_News {
    ... on WpPage_Pagefields_Page_News {
      fieldGroupName
      title
      ingress
      previewNumber
      buttonText
      buttonType
      buttonLink {
        ... on WpPage {
          uri
        }
      }
      buttonAction
      buttons {
        buttonText
        buttonLinkType
        buttonLink {
          ... on WpPage {
            uri
          }
        }
        buttonLinkExternal
      }
      previewNumber
    }
  }
`;

export default News;
