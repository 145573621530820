import { create, ApisauceInstance } from 'apisauce';

export class ApiClient {
  client: ApisauceInstance;

  constructor() {
    this.client = create({
      baseURL: process.env.GATSBY_WP_API_URL,
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      },
      timeout: 10000,
      withCredentials: true,
    });
  }

  sendContactForm = async data => {
    return this.client.post<Response>('swace/v1/sendContactForm', data);
  };

  portfolioPageLogin = async data => {
    return this.client.post<Response>('swace/v1/portfolios/login', data);
  };

  portfolioRequestPassword = async data => {
    return this.client.post<Response>(
      'swace/v1/portfolios/requestPassword',
      data
    );
  };
}
