import * as React from 'react';
import InputField from '../ApplyForm/InputField';
import Button from '../../swace/button';
import * as s from './Newsletterform.module.scss';
import Text from '../Text';
import axios from 'axios';

export interface NewsletterformProps {
  emailHeading: string;
  submitButton: string;
  _sitemeta: {
    url: string;
  };
}

interface State {
  email: string;
  errorText: string;
  successText: string;
  submitted: boolean;
}

class Newsletterform extends React.Component<NewsletterformProps, State> {
  constructor(props: NewsletterformProps) {
    super(props);
    this.state = {
      email: '',
      errorText: '',
      successText: '',
      submitted: false,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { _sitemeta } = this.props;

    const form = e.target;
    const formData = new FormData(form);

    this.setState({ errorText: '' });
    this.setState({ successText: '' });

    axios
      .post(_sitemeta.url + '/wp-json/swace/v1/newsletter_form', formData)
      .then((response) => {
        this.setState({
          successText: response && response.data && response.data.response,
          submitted: true,
        });
      })
      .catch((error) => {
        this.setState({
          errorText: error && error.response && error.response.data,
        });
      });
  };

  render() {
    const { emailHeading, submitButton } = this.props;
    const { email, errorText, successText, submitted } = this.state;

    return (
      <>
        {submitted ? (
          <Text h4>
            {successText ? successText : 'Tack för ditt intresse!'}
          </Text>
        ) : (
          <>
            <Text h6>{emailHeading}</Text>
            <form
              id="cision-newsletter-form"
              onSubmit={this.onSubmit}
              className={`${s.formWrapper} mt-2`}
            >
              <InputField
                type="email"
                name="email"
                label=""
                onChange={(e) => this.setState({ email: e.target.value })}
                value={email}
                required
                customBootstrap="col-12 col-md-8 col-lg-4 mr-lg-4 px-0"
              />
              <Button
                className={`${s.submitButton} col-12 col-md-3 offset-0 offset-md-1 offset-lg-0 col-lg-2 mt-3 mt-md-0 ml-md-3 ml-0`}
                notFixedWidth
                filled
                onClick={() => {}}
                type="submit"
              >
                <div>{submitButton}</div>
              </Button>
              <div className="col-12 p-0 mt-3">
                {errorText && errorText.length > 0 && (
                  <div className={s.errortext}>
                    {errorText ? errorText : ''}
                  </div>
                )}
              </div>
            </form>
          </>
        )}
      </>
    );
  }
}

export default Newsletterform;
