import * as React from 'react';
import { graphql } from 'gatsby';
import { Newsposts as NewspostsType } from '../../types';
import * as s from './Newsposts.module.scss';
import Section from '../shared/Section';
import Button from '../swace/button';
import Grid from '../shared/Grid';
import Reveal from '../swace/reveal';
import Text from '../shared/Text';
import LinkGroup from '../shared/LinkGroup';

export interface NewspostsProps {
  fieldGroupName: string;
  title: string;
  intro: string;
  displaySubscribe: boolean;
  subscribeLink: string;
  buttonTitle: string;
  _newsposts: NewspostsType;
}

interface State {
  previewNumber: number;
}

class Newsposts extends React.Component<NewspostsProps, State> {
  state = {
    previewNumber: 3,
  };

  showMore = (previewNumber) => {
    this.setState({
      previewNumber: (previewNumber = Infinity),
    });
  };

  render() {
    const {
      title,
      intro,
      _newsposts: newsposts,
      displaySubscribe,
      subscribeLink,
      buttonTitle,
    } = this.props;

    const { previewNumber } = this.state;
    const numberOfPosts = newsposts.edges.length;
    const actionLinks = [];

    if (displaySubscribe) {
      actionLinks.unshift({
        button_text: 'Subscribe',
        button_function: null,
        button_link: subscribeLink || 'https://zoundindustries.com',
      });
    }

    return (
      <Section>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <Reveal drop>
              <Text h2>{title}</Text>
            </Reveal>
            <Reveal lift>
              <Text ingressMedium>{intro}</Text>
              <LinkGroup buttons={actionLinks} />
            </Reveal>
          </div>
        </div>
        <Grid
          items={newsposts.edges
            .filter((n) => n.node.uri !== 'swace_newspost_placeholder')
            .slice(0, previewNumber)
            .map((n) => {
              const { newsPost, uri, id } = n.node;
              const { images, publishDate } = newsPost;
              return {
                title: newsPost.title,
                href: `${uri}`,
                key: id,
                image: images ? images[0] : null,
                tags: [
                  `${publishDate.slice(0, 10)} ${publishDate.slice(11, 16)}`,
                ],
              };
            })}
        />
        {previewNumber <= numberOfPosts && (
          <div className="row">
            <div className="col-12 col-sm-8 col-md-6 col-lg-4 offset-sm-2 offset-md-3 offset-lg-4 d-flex justify-content-center">
              <Reveal lift>
                <Button
                  className={s.button}
                  onClick={() => this.showMore(previewNumber)}
                  filled
                >
                  {buttonTitle}
                </Button>
              </Reveal>
            </div>
          </div>
        )}
      </Section>
    );
  }
}

export const query = graphql`
  fragment NewspostsFragment on WpPage_Pagefields_Page_Newsposts {
    ... on WpPage_Pagefields_Page_Newsposts {
      fieldGroupName
      title
      intro
      displaySubscribe
      subscribeLink
      buttonTitle
    }
  }
`;

export default Newsposts;
