import * as React from 'react';
import { graphql } from 'gatsby';
import { Image } from '../../types';
import * as s from './TextAndImage.module.scss';
import Section from '../shared/Section';
import Text from '../shared/Text';
// import Button from "@bit/swacedigital.components.button";
import Button from '../swace/button';
import BackgroundImage from '../swace/gatsby-background-image';
import Reveal from '../swace/reveal';
import LinkGroup from '../shared/LinkGroup';
import whiteCitationSvg from '../../img/white_citation.svg';
import {
  Vertical,
  Horizontal,
} from '../swace/gatsby-background-image/GatsbyBackgroundImage';

interface TextImage {
  image: {
    image: Image;
    caption: string;
    hasOverlayText: boolean;
    imagePositioning: {
      vertical: Vertical;
      horizontal: Horizontal;
    };
  };
  textOrCitation: {
    text: string;
    isCitation: boolean;
    byline: string;
  };
}

interface TextExtra {
  title: string;
  text: string;
}

export interface Props {
  id: number;
  blockChoices: {
    leftImageSmall: boolean;
    captionOrTextsBelow: string;
    hasButtons: boolean;
  };
  title: string;
  ingress: string;
  buttons: Array<{
    buttonLink: {
      uri: string;
    };
    buttonText: string;
  }>;
  images: TextImage[];
  texts: TextExtra[];
}

const ImageOverlayText = (e, images) => {
  if (e.image.hasOverlayText) {
    const { text, isCitation, byline } = e.textOrCitation;
    return (
      <div
        className={[
          s.image,
          s.overlay,
          images.length === 1 ? '' : s.overlaySmall,
          'd-flex flex-column',
          isCitation ? 'justify-content-end' : 'justify-content-center',
        ].join(' ')}
      >
        <div className={images.length === 1 ? 'container' : ''}>
          <div className={'row'}>
            <div
              className={[
                images.length === 1 ? 'col-12 col-md-10 offset-md-1' : 'col',
              ].join(' ')}
            >
              {isCitation && (
                <img src={whiteCitationSvg} className={s.citationImg} />
              )}
              <Text ingressLarge light>
                {text}
              </Text>
            </div>
          </div>
          <div className={['row', s.byline].join(' ')}>
            <div
              className={[
                images.length === 1
                  ? 'col col-auto offset-md-1'
                  : 'col col-auto',
                s.dashStyle,
              ].join(' ')}
            >
              <Text byline teal span>
                &#8211;{' '}
              </Text>
            </div>
            <div className={`col col-9`}>
              <Text byline teal span>
                {byline}
              </Text>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div className={s.image} />;
};

class TextAndImage extends React.Component<Props> {
  render() {
    const { blockChoices, title, ingress, buttons, images, texts } = this.props;
    const { leftImageSmall, captionOrTextsBelow, hasButtons } = blockChoices;
    const bootstrapCol = {
      left: images && images.length === 1 ? '12' : leftImageSmall ? '5' : '7',
      right: leftImageSmall ? '7' : '5',
    };
    const heightStyle = {
      left: bootstrapCol.left === '5' ? s.imageLow : s.imageHigh,
      right: bootstrapCol.right === '5' ? s.imageLow : s.imageHigh,
    };

    return (
      <React.Fragment>
        <Section slimBottom={!!images || !!texts}>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <Reveal drop>
                <Text h2>{title}</Text>
              </Reveal>
              <Reveal lift>
                <Text ingress>{ingress}</Text>
                {hasButtons && buttons && buttons.length > 0 && (
                  <LinkGroup buttons={buttons} thickBottom />
                )}
              </Reveal>
            </div>
          </div>
        </Section>
        {images && (
          <Section
            wide
            slimBottom={texts && texts.length > 0 && texts[0].title !== ''}
            slimTop={hasButtons}
          >
            <Reveal lift>
              <div className={['row', s.imageWrapper].join(' ')}>
                {images &&
                  images.map((e, i) => (
                    <div
                      key={i}
                      className={[
                        'col-12',
                        `col-sm-${
                          i === 0 ? bootstrapCol.left : bootstrapCol.right
                        }`,
                        i === 0 ? heightStyle.left : heightStyle.right,
                      ].join(' ')}
                    >
                      <BackgroundImage
                        image={e.image.image}
                        imagePositioning={e.image.imagePositioning}
                      >
                        {ImageOverlayText(e, images)}
                      </BackgroundImage>
                    </div>
                  ))}
              </div>
              {captionOrTextsBelow === 'captions' && (
                <div className={`row ${s.captionRow}`}>
                  {images &&
                    images.map((e, i) => (
                      <div
                        key={i}
                        className={`col-12 col-sm-${
                          i === 0 ? bootstrapCol.left : bootstrapCol.right
                        }`}
                      >
                        <div
                          className={[
                            s.caption,
                            i === 0 ? s.left : s.right,
                          ].join(' ')}
                        >
                          <Text caption>{e.image.caption}</Text>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </Reveal>
          </Section>
        )}
        {captionOrTextsBelow === 'text_blocks' && texts && texts.length > 0 && (
          <Section slimTop>
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="row">
                  {texts &&
                    texts.map((textPart) => (
                      <div className="col-12 col-md" key={textPart.title}>
                        <div className={s.textExtraTitle}>
                          <Reveal drop>
                            <Text h5>{textPart.title}</Text>
                          </Reveal>
                        </div>
                        <div className={s.textExtraText}>
                          <Reveal lift>
                            <Text pSmall preLine>
                              {textPart.text}
                            </Text>
                          </Reveal>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </Section>
        )}
      </React.Fragment>
    );
  }
}

export const query = graphql`
  fragment TextAndImageBlockFragment on WpPage_Pagefields_Page_TextAndImage {
    ... on WpPage_Pagefields_Page_TextAndImage {
      fieldGroupName
      blockChoices {
        leftImageSmall
        captionOrTextsBelow
        hasButtons
      }
      title
      ingress
      buttons {
        buttonText
        buttonLinkType
        buttonLink {
          ... on WpPage {
            uri
          }
        }
        buttonLinkExternalUrl
        buttonLinkFile {
          mediaItemUrl
        }
      }
      images {
        image {
          image {
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          caption
          hasOverlayText
          imagePositioning {
            vertical: verticalAdjustment
            horizontal: horizontalAdjustment
          }
        }
        textOrCitation {
          text
          isCitation
          byline
        }
      }
      texts {
        title
        text
      }
    }
  }
`;

export default TextAndImage;
