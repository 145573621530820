import * as React from 'react';
import { graphql } from 'gatsby';
import Section from '../shared/Section';
import Text from '../shared/Text';
import * as s from './Citation.module.scss';
import blueCitationSvg from '../../img/new_blue_citation.svg';
import Reveal from '../swace/reveal';

export interface Props {
  fieldGroupName: string;
  citation: string;
  byline: string;
}

class Citation extends React.Component<Props> {
  render() {
    const { citation, byline } = this.props;

    return (
      <Section slimTop>
        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-md-10 offset-md-1'}>
              <Reveal drop>
                <img src={blueCitationSvg} className={s.citationImg} />
              </Reveal>
              <Reveal lift>
                <Text ingressLarge>{citation}</Text>
              </Reveal>
            </div>
          </div>
          <div className={['row', s.byline].join(' ')}>
            <div className={['col col-auto offset-md-1'].join(' ')}>
              <Reveal lift>
                <Text byline teal span>
                  &#8211;{' '}
                </Text>
              </Reveal>
            </div>
            <div className={`col col-9`}>
              <Reveal lift>
                <Text byline teal span>
                  {byline}
                </Text>
              </Reveal>
            </div>
          </div>
        </div>
      </Section>
    );
  }
}

export const query = graphql`
  fragment CitationBlockFragment on WpPage_Pagefields_Page_Citation {
    ... on WpPage_Pagefields_Page_Citation {
      fieldGroupName
      citation
      byline
    }
  }
`;

export default Citation;
