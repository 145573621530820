import * as React from 'react';
import Reveal from '../swace/reveal';
import BackgroundImage from '../swace/gatsby-background-image';
import Text from './Text';
import { Link } from 'gatsby';
import plusSvg from '../../img/Plus.svg';
import * as s from './GridItem.module.scss';
import { DynamicLink } from './components';

const isExternalLink = type => !!type && type !== 'page';

const renderBackgroundImage = image => (
  <BackgroundImage
    image={image}
    className={`${s.backgroundImage} ${image ? '' : ''}`}
    imageClass={s.image}
  >
    <div className={s.wrapper}>
      {image ? (
        <div
          className={s.newsItem}
          style={{
            backgroundImage: typeof image === 'string' ? `url(${image})` : '',
          }}
        />
      ) : (
        <img
          className={s.placeholderImage}
          src={require('../../img/grid-item-image/grid-item-image.png')}
        />
      )}
      <div className={s.newsCornerWrapper}>
        <Reveal lift>
          <>
            <div className={s.newsCorner} />
            <img src={plusSvg} className={s.plus} />
          </>
        </Reveal>
      </div>
    </div>
  </BackgroundImage>
);

const GridItem = ({ title, image, tags, href, type }) => (
  <div className={`col-12 col-md-4 ${s.newsItemContainer}`}>
    <Reveal lift>
      {isExternalLink(type) ? (
        <a href={href} download target="_blank">
          {renderBackgroundImage(image)}
        </a>
      ) : (
        <DynamicLink to={href}>{renderBackgroundImage(image)}</DynamicLink>
      )}
    </Reveal>
    <Reveal lift>
      <div className={s.newsItemLabel}>
        <Text label teal>
          {tags &&
            tags.map((tag, i) => `${tag}${i < tags.length - 1 ? ' • ' : ''}`)}
        </Text>
      </div>
      <div className={s.newsItemText}>
        <Text ingress>{title}</Text>
        {type === 'file' ? (
          <a href={href} download target="_blank">
            <Text pMedium teal>
              Press here to download
            </Text>
          </a>
        ) : (
          ''
        )}
      </div>
    </Reveal>
  </div>
);

export default GridItem;
