import { graphql } from 'gatsby';
import * as React from 'react';
import Section from '../shared/Section';
import * as s from './FullScreenImage.module.scss';
import { Image } from '../../types';
import BackgroundImage, {
  Horizontal,
  Vertical,
} from '~components/swace/gatsby-background-image';

export interface FullScreenImageProps {
  id: number;
  desktopImage: Image;
  mobileImage: Image;
  internal: {
    type: string;
  };
}

interface FullScreenImageState {
  isMobile: boolean;
}

class FullScreenImage extends React.Component<
  FullScreenImageProps,
  FullScreenImageState
> {
  state = { isMobile: false };

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;
    this.setState({ isMobile });
  };

  render() {
    const { isMobile } = this.state;
    const { desktopImage, mobileImage } = this.props;
    const image = isMobile && !!mobileImage ? mobileImage : desktopImage;
    return (
      <Section slim wide sectionName="FullScreenImage">
        <BackgroundImage
          image={image}
          className={s.image}
          imagePositioning={{
            horizontal: Horizontal.center,
            vertical: Vertical.center,
          }}
          objectFit="contain"
        />
      </Section>
    );
  }
}

export const query = graphql`
  fragment FullScreenImageBlockFragment on WpPage_Pagefields_Page_FullScreenImage {
    ... on WpPage_Pagefields_Page_FullScreenImage {
      fieldGroupName
      desktopImage {
        sourceUrl
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      mobileImage {
        sourceUrl
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default FullScreenImage;
