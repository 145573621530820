import * as React from 'react';
import * as s from './Connect.module.scss';
import { graphql } from 'gatsby';
import Button from '../swace/button';
import Text from '../shared/Text';
import RadioButton from '../shared/RadioButton';
import InputField from '../shared/ApplyForm/InputField';
import Section from '../shared/Section';
import axios from 'axios';

export interface Props {
  title: string;
  ingress: string;
  buttonText: string;
  privacyPolicyText: string;
  url: string;
  _sitemeta: Array<{
    url: string;
  }>;
  _pageTitle: string;
  _departments;
}

interface State {
  email: string;
  isTextAreaFocused: boolean;
  errorText: string;
  successText: string;
  selected: string;
  currentRadio: string;
  privacyClosed: boolean;
}

class Connect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      isTextAreaFocused: false,
      errorText: '',
      successText: '',
      selected: '',
      currentRadio: '',
      privacyClosed: true,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { _sitemeta } = this.props;
    const url = _sitemeta['url'];
    const { currentRadio } = this.state;
    const formData = new FormData(e.target);
    formData.append('department_id', currentRadio);
    this.setState({ errorText: '' });
    this.setState({ successText: '' });

    axios
      .post(url + '/wp-json/teamtailor/v1/connect_with_us', formData)
      .then((response) => {
        this.setState({ successText: response.data });
      })
      .catch((error) => {
        this.setState({ errorText: error.response.data[0] });
      });
  };

  render() {
    const {
      title,
      buttonText,
      ingress,
      privacyPolicyText,
      _departments,
      _pageTitle,
    } = this.props;
    const { email, errorText, successText, currentRadio, privacyClosed } =
      this.state;
    const departments = _departments
      ? _departments.edges.map((department) => ({
          id: department.node.id,
          name: department.node.attributes.name,
        }))
      : [];
    return (
      <Section>
        <div className="row">
          <div className="col-md-10 col-xl-7 offset-md-1 offset-xl-2">
            <Text h3>{_pageTitle}</Text>
            <form className={s.form} onSubmit={this.onSubmit}>
              <Text h6>{title}</Text>
              <Text p>{ingress}</Text>
              <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                <div className="row">
                  {departments &&
                    departments.map((department) => (
                      <RadioButton
                        key={department.id}
                        departmentId={department.id}
                        radioButtonText={department.name}
                        currentId={currentRadio}
                        onChange={() =>
                          this.setState({
                            currentRadio: department.id,
                          })
                        }
                      />
                    ))}
                  <RadioButton
                    key={'0'}
                    departmentId={'0'}
                    radioButtonText={'All'}
                    currentId={currentRadio}
                    onChange={() => this.setState({ currentRadio: '0' })}
                  />
                </div>
                <InputField
                  mTop
                  type="email"
                  name="email"
                  label="E-mail"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  value={email}
                  required
                  fullWidth
                />
                <div className="col-12 d-flex justify-content-center">
                  <div className={s.successtext} id="successtext">
                    {successText}
                  </div>
                  <div className={s.errortext} id="errortext">
                    {errorText}
                  </div>
                </div>
                <Button wide filled onClick={() => {}} type="submit">
                  <div>{buttonText}</div>
                </Button>
                <Text pSmall>
                  I have read the
                  <Button
                    type="button"
                    slim
                    p0
                    dark
                    fontRegular
                    onClick={() =>
                      this.setState({ privacyClosed: !privacyClosed })
                    }
                  >
                    Privacy Policy
                  </Button>
                  and confirm that Zound Industries International AB store my
                  personal details to be able to contact me for job
                  opportunities.
                </Text>
                <div
                  className={s.body}
                  hidden={privacyClosed}
                  dangerouslySetInnerHTML={{ __html: privacyPolicyText }}
                />
              </div>
            </form>
          </div>
        </div>
      </Section>
    );
  }
}

export const query = graphql`
  fragment ConnectBlockFragment on WpPage_Pagefields_Page_Connect {
    ... on WpPage_Pagefields_Page_Connect {
      fieldGroupName
      title
      ingress
      buttonText
      privacyPolicyText
    }
  }
  fragment TeamtailorDepartmentFragment on Query {
    allDepartment {
      edges {
        node {
          id
          attributes {
            name
          }
        }
      }
    }
  }
`;

export default Connect;
