import * as React from 'react';
import { graphql } from 'gatsby';
import { Brand } from '~types';
import * as s from './Brands.module.scss';
import Section from '~components/shared/Section';
import Text from '~components/shared/Text';
import Reveal from '~components/swace/reveal';
import { ScrollSlider } from '~components/shared/slider';
import colors from '~colors';

export interface Props {
  id: number;
  title: string;
  text: string;
  brands: Brand[];
  time: string;
}

interface State {
  currentIndex: number;
  time: number;
  timerId: any;
}

class Brands extends React.Component<Props, State> {
  render() {
    const { title, time, text, brands } = this.props;
    const slides = brands.map((brand) => ({
      image: brand.image,
      text: brand.text,
      link: brand.link.uri,
      thumbnail: brand.logotype,
      thumbnailActive: brand.logotypeActive,
    }));

    return (
      <>
        <Section slimBottom sectionName="Brands">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-9 offset-md-1">
              <Reveal drop>
                <Text h2>{title}</Text>
              </Reveal>
              <Reveal lift>
                <div>
                  <Text p>{text}</Text>
                </div>
              </Reveal>
            </div>
          </div>
        </Section>
        <Section wide slimTop>
          <div className="container-fluid">
            <Reveal lift>
              <div className="row">
                <div className="col-12">
                  <ScrollSlider
                    slides={slides}
                    color={colors.WHITE}
                    time={time}
                    displayThumbnails
                  />
                </div>
              </div>
            </Reveal>
          </div>
        </Section>
      </>
    );
  }
}

export const query = graphql`
  fragment BrandsBlockFragment on WpPage_Pagefields_Page_Brands {
    ... on WpPage_Pagefields_Page_Brands {
      # id
      fieldGroupName
      title
      text
      brands {
        title
        link: linkToPage {
          ... on WpPage {
            uri
          }
        }
        logotype {
          localFile {
            childImageSharp {
              fluid(maxWidth: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        logotypeActive {
          localFile {
            childImageSharp {
              fluid(maxWidth: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        text
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1280, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      time
    }
  }
`;

export default Brands;
