import * as React from 'react';
import GridItem from './GridItem';

const Grid = ({ items }) => (
  <div className="row">
    {items.map(n => (
      <GridItem
        key={n.key}
        title={n.title}
        image={n.image}
        tags={n.tags}
        href={n.href}
        type={n.type}
      />
    ))}
  </div>
);

export default Grid;
