// extracted by mini-css-extract-plugin
export var active = "ScrollSlider-module--active--1irJN";
export var attributes = "ScrollSlider-module--attributes--yNSDP";
export var contentImage = "ScrollSlider-module--contentImage--ufueC";
export var currentSlide = "ScrollSlider-module--currentSlide--xdy3l";
export var dot = "ScrollSlider-module--dot--6r0N9";
export var dots = "ScrollSlider-module--dots--HOQlX";
export var image = "ScrollSlider-module--image--8yML3";
export var text = "ScrollSlider-module--text--6pnkA";
export var thumbnail = "ScrollSlider-module--thumbnail--Cwmp8";
export var thumbnailImage = "ScrollSlider-module--thumbnailImage--EaW-5";
export var thumbnailsWrapper = "ScrollSlider-module--thumbnailsWrapper--6flbD";
export var title = "ScrollSlider-module--title--VK1s2";
export var wrapper = "ScrollSlider-module--wrapper--XQy29";