// extracted by mini-css-extract-plugin
export var bold = "FreeText-module--bold--rMqwS";
export var byline = "FreeText-module--byline--w1jzz";
export var caption = "FreeText-module--caption--+I++2";
export var captionSmall = "FreeText-module--captionSmall--Yl-hZ";
export var center = "FreeText-module--center--rzM33";
export var dark = "FreeText-module--dark--8QGeC";
export var h1 = "FreeText-module--h1--PMKy6";
export var h2 = "FreeText-module--h2--l32+5";
export var h3 = "FreeText-module--h3--ld6yG";
export var h4 = "FreeText-module--h4--u1B02";
export var h5 = "FreeText-module--h5--mfoOX";
export var h6 = "FreeText-module--h6--yF+7D";
export var ingress = "FreeText-module--ingress--PvtKr";
export var ingressLarge = "FreeText-module--ingressLarge--l+6eT";
export var ingressMedium = "FreeText-module--ingressMedium--tFa2c";
export var italic = "FreeText-module--italic--RYkQh";
export var label = "FreeText-module--label--OiV6i";
export var left = "FreeText-module--left--ZH+Ud";
export var light = "FreeText-module--light--GS9a8";
export var pBread = "FreeText-module--pBread--Kk6yq";
export var pMedium = "FreeText-module--pMedium--8rcnV";
export var pSmall = "FreeText-module--pSmall--pX01F";
export var preLine = "FreeText-module--preLine--3Mpg6";
export var teal = "FreeText-module--teal--0Rdrc";
export var wrapper = "FreeText-module--wrapper--EJsuR";