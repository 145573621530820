import * as React from 'react';
import { graphql } from 'gatsby';
import { Image } from '../../types';
import HeroComponent from '../shared/Hero';
import {
  Vertical,
  Horizontal,
} from '../swace/gatsby-background-image/GatsbyBackgroundImage';

export interface Props {
  fieldGroupName: string;
  background: string;
  overlay: string;
  title: string;
  ingress: string;
  logotype: Image;
  backgroundImage: Image;
  imagePositioning: {
    vertical: Vertical;
    horizontal: Horizontal;
  };
  backgroundVideo: {
    link: string;
  };
  buttonText: string;
  buttonLink: string;
  showMuteButton: boolean;
}

class Hero extends React.Component<Props> {
  render() {
    return <HeroComponent {...this.props} />;
  }
}

export const query = graphql`
  fragment HeroBlockFragment on WpPage_Pagefields_Page_Hero {
    ... on WpPage_Pagefields_Page_Hero {
      fieldGroupName
      background
      overlay
      title
      ingress
      showMuteButton
      logotype {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      backgroundImage {
        sourceUrl
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      imagePositioning {
        vertical: verticalAdjustment
        horizontal: horizontalAdjustment
      }
      backgroundVideo {
        link
      }
      buttonText
      buttonLink {
        ... on WpPost {
          uri
        }
        ... on WpPage {
          uri
        }
        ... on WpNewsItem {
          uri
        }
        ... on WpPerson {
          uri
        }
        ... on WpOffice {
          uri
        }
        ... on WpAnnualReport {
          uri
        }
      }
    }
  }
`;
export default Hero;
