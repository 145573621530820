// extracted by mini-css-extract-plugin
export var active = "Timeline-module--active--vJmc1";
export var attributes = "Timeline-module--attributes--Mit3P";
export var data1 = "Timeline-module--data1--fqAgF";
export var data2 = "Timeline-module--data2--feL0L";
export var large = "Timeline-module--large--mT2wt";
export var previous = "Timeline-module--previous--31FUO";
export var slide = "Timeline-module--slide--g2dnL";
export var slides = "Timeline-module--slides--KiRhD";
export var text = "Timeline-module--text--kuPw+";
export var title = "Timeline-module--title--Siw3S";
export var value = "Timeline-module--value--EEmya";
export var wrapper = "Timeline-module--wrapper--phId9";
export var year = "Timeline-module--year--Ja8pA";
export var year1 = "Timeline-module--year1--koBHn";
export var year2 = "Timeline-module--year2--N+wfl";
export var year3 = "Timeline-module--year3--8PRSA";
export var year4 = "Timeline-module--year4--o1FXc";
export var year5 = "Timeline-module--year5--3w-6Q";
export var yearn = "Timeline-module--yearn---TnKV";
export var yearn1 = "Timeline-module--yearn1--XItkz";
export var yearp = "Timeline-module--yearp--YCj9a";
export var yearp1 = "Timeline-module--yearp1--hW0a3";
export var years = "Timeline-module--years--bhJax";