import { graphql } from 'gatsby';
import * as React from 'react';
import { Image } from '../../types';
import CountUp from '../shared/CountUp';
import Section from '../shared/Section';
import BackgroundImage from '../swace/gatsby-background-image';
import Reveal from '../swace/reveal';
import * as s from './Timeline.module.scss';

interface Slide {
  image: Image;
  fieldGroupName: string;
  text: string;
  data1: string;
  data2: string;
  year: string;
}

export interface Props {
  fieldGroupName: string;
  data1Title: string;
  data2Title: string;
  slides: Slide[];
}

interface State {
  previousIndex?: number;
  currentIndex: number;
  currentSlide: number;
  lastIndex: number;
  time: number;
  timerId: any;
}

class Timeline extends React.Component<Props, State> {
  state = {
    previousIndex: 0,
    currentIndex: 0,
    currentSlide: 0,
    lastIndex: 0,
    time: 0,
    timerId: null,
  };

  setCurrentIndex = (index: number) => {
    const { currentIndex } = this.state;

    if (index == currentIndex) return;

    this.setState({ time: 0 });

    const prevIndex = currentIndex;

    this.setState({
      currentIndex: index,
      previousIndex: prevIndex,
      currentSlide: index,
    });

    setTimeout(() => {
      this.setState({ previousIndex: undefined });
    }, 400);
  };

  goToNextIndex = () => {
    const { currentIndex, lastIndex } = this.state;
    const nextIndex = currentIndex + 1 < lastIndex ? currentIndex + 1 : 0;
    this.setCurrentIndex(nextIndex);
  };

  setTimer = () => {
    const { time } = this.state;

    if (time === 8) {
      this.goToNextIndex();
      this.setState({ time: 0 });
    }

    this.setState({ time: time < 8 ? time + 1 : 0 });
  };

  componentDidMount() {
    const { slides } = this.props;
    const timerId = setInterval(this.setTimer, 1000);
    this.setState({ timerId });
    const years = slides.map((slide) => parseInt(slide.year, 10));
    this.setState({ lastIndex: years.length });
  }

  componentWillUnmount() {
    const { timerId } = this.state;
    clearInterval(timerId);
  }

  render() {
    const {
      fieldGroupName,
      slides,
      data1Title: data1Title,
      data2Title: data2Title,
      _blocks,
    } = this.props;

    const { previousIndex, currentIndex, currentSlide } = this.state;

    const blockIndex = _blocks.findIndex(
      (b) => b.fieldGroupName === fieldGroupName
    );
    const slimBottom =
      _blocks.length - 2 >= blockIndex &&
      (_blocks[blockIndex + 1].fieldGroupName.endsWith('Slider') ||
        _blocks[blockIndex + 1].fieldGroupName.endsWith('Visit'));

    const slimTop =
      blockIndex > 0 &&
      (_blocks[blockIndex - 1].fieldGroupName.endsWith('Slider') ||
        _blocks[blockIndex - 1].fieldGroupName.endsWith('Visit'));

    const yearsArray = slides.map((slide) => parseInt(slide.year, 10));
    yearsArray.sort();

    const yearsIndexArray = yearsArray.map((year, index) => index);

    const sortedSlides = slides.sort((a, b) =>
      parseInt(a.year, 10) >= parseInt(b.year, 10) ? 1 : -1
    );

    return (
      <Section wide slimBottom={slimBottom} slimTop={slimTop}>
        <div className={s.wrapper}>
          <div className={s.slides}>
            {sortedSlides.map((slide, index) => (
              <div
                key={`${slide.year}_${slide.text}`}
                data-id={index}
                className={`${s.slide} ${
                  index === currentSlide ? s.active : ''
                } ${index === previousIndex ? s.previous : ''}`}
              >
                <BackgroundImage image={slide.image} />
              </div>
            ))}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-5 offset-md-1">
                <div className={s.attributes}>
                  <div />
                  {sortedSlides[currentSlide].text && (
                    <div
                      className={`${s.text} ${
                        !sortedSlides[currentSlide].data1 &&
                        !sortedSlides[currentSlide].data2
                          ? s.large
                          : ''
                      }`}
                    >
                      <Reveal key={sortedSlides[currentSlide].text} fade>
                        {sortedSlides[currentSlide].text}
                      </Reveal>
                    </div>
                  )}

                  {sortedSlides[currentSlide].data1 && (
                    <div className={s.data1}>
                      <div className={s.title}>{data1Title}</div>
                      <div className={s.value}>
                        <CountUp
                          key={sortedSlides[currentSlide].data1}
                          to={parseInt(sortedSlides[currentSlide].data1, 10)}
                        />
                      </div>
                    </div>
                  )}
                  {sortedSlides[currentSlide].data2 && (
                    <div className={s.data2}>
                      <div className={s.title}>{data2Title}</div>
                      <div className={s.value}>
                        <CountUp
                          key={sortedSlides[currentSlide].data2}
                          to={parseInt(sortedSlides[currentSlide].data2, 10)}
                        />
                      </div>
                    </div>
                  )}
                  <div />
                </div>
              </div>
            </div>
            <div className={s.years}>
              {sortedSlides.map((slide, index) => {
                let order;

                if (yearsIndexArray.indexOf(index) < currentIndex - 3) {
                  order = 'n1';
                }
                if (yearsIndexArray.indexOf(index) === currentIndex - 3) {
                  order = 'n';
                }
                if (yearsIndexArray.indexOf(index) === currentIndex - 2) {
                  order = 1;
                }
                if (yearsIndexArray.indexOf(index) === currentIndex - 1) {
                  order = 2;
                }
                if (yearsIndexArray.indexOf(index) === currentIndex) {
                  order = 3;
                }
                if (yearsIndexArray.indexOf(index) === currentIndex + 1) {
                  order = 4;
                }
                if (yearsIndexArray.indexOf(index) === currentIndex + 2) {
                  order = 5;
                }
                if (yearsIndexArray.indexOf(index) === currentIndex + 3) {
                  order = 'p';
                }
                if (yearsIndexArray.indexOf(index) > currentIndex + 3) {
                  order = 'p1';
                }

                return (
                  <div
                    key={`${slide.year}_${slide.text}`}
                    data-id={index}
                    className={`${s.year} ${s[`year${order}`]}`}
                    onClick={() => {
                      this.setCurrentIndex(index);
                    }}
                  >
                    {slide.year}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Section>
    );
  }
}

export const query = graphql`
  fragment TimelineBlockFragment on WpPage_Pagefields_Page_Timeline {
    ... on WpPage_Pagefields_Page_Timeline {
      fieldGroupName
      data1Title
      data2Title
      slides {
        fieldGroupName
        text
        data1
        data2
        year
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;

export default Timeline;
