import * as React from 'react';
import BackgroundImage from '../swace/gatsby-background-image';
import Reveal from '../swace/reveal';
import Section from '../shared/Section';
import { graphql } from 'gatsby';
import * as s from './Visit.module.scss';
import { Image } from '../../types';
import {
  Vertical,
  Horizontal,
} from '../swace/gatsby-background-image/GatsbyBackgroundImage';

export interface Props {
  title: string;
  url: string;
  image: Image;
  imagePositioning: {
    vertical: Vertical;
    horizontal: Horizontal;
  };
}

const Visit = ({ title, url, image, imagePositioning }: Props) => (
  <Section wide slim sectionName="Visit">
    <BackgroundImage
      className={s.backgroundImage}
      image={image}
      imagePositioning={imagePositioning}
    >
      <div className={s.contentWrapper}>
        <Reveal fade lift>
          <div className={s.textWrapper}>
            <h3 className={s.visit}>Visit </h3>
            <a className={s.link} target="_blank" href={url}>
              <h3>{title}</h3>
            </a>
          </div>
        </Reveal>
      </div>
    </BackgroundImage>
  </Section>
);

export const query = graphql`
  fragment VisitBlockFragment on WpPage_Pagefields_Page_Visit {
    ... on WpPage_Pagefields_Page_Visit {
      fieldGroupName
      title
      url
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      imagePositioning {
        vertical: verticalAdjustment
        horizontal: horizontalAdjustment
      }
    }
  }
`;

export default Visit;
