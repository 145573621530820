import * as React from 'react';
import * as s from './ScrollSlider.module.scss';
import { Image } from '~types';
import Reveal from '~components/swace/reveal';
import Img from 'gatsby-image';
import { BasicSlider } from './BasicSlider';
import GatsbyImage from 'gatsby-image';
import Text from '~components/shared/Text';

interface Slide {
  image: Image;
  thumbnail: Image;
  thumbnailActive: Image;
  content_image: Image;
  text: string;
  title: string;
  link: Location;
}

export interface ScrollSliderProps {
  slides: Slide[];
  color?: string;
  displayThumbnails: boolean;
  time?: string;
  interval: number;
}

interface State {
  currentSlide?: number;
  isInTransition: boolean;
  locked: boolean;
  dragging: boolean | number;
  currentX: number;
  direction?: boolean;
  time: number;
  timerId: any;
}

export class ScrollSlider extends React.Component<ScrollSliderProps, State> {
  private elementRef = React.createRef<any>();
  private sliderRef = React.createRef<BasicSlider>();

  state = {
    currentSlide: null,
    isInTransition: false,
    locked: false,
    dragging: false,
    currentX: 0,
    direction: false,
    time: 0,
    timerId: null,
  };

  componentDidMount() {
    const timerId = setInterval(this.setTimer, 1000);

    this.setState({ timerId });
  }

  componentWillUnmount() {
    clearInterval(this.state.timerId);
  }

  setTimer = () => {
    let { time: slideTime } = this.props;
    const { time } = this.state;

    if (time === parseInt(slideTime)) {
      this.sliderRef.current.next();
    }

    this.setState({ time: time < parseInt(slideTime) ? time + 1 : 0 });
  };

  goToIndex = (index: number) => {
    this.sliderRef.current.goToIndex(index);
    this.setState({ currentSlide: index });
  };

  onClickHandler = () => {
    const currentIndex = this.state.currentSlide;
    if (this.sliderRef.current.props.slides[currentIndex].link !== undefined) {
      window.location = this.sliderRef.current.props.slides[currentIndex].link;
    }
  };

  handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    const firstTouch = event.touches[0];
    if (firstTouch) {
      this.setState({ currentX: firstTouch.clientX });
    }
  };

  handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    const { currentX } = this.state;
    const xUp = event.touches[0].clientX;
    const xDiff = currentX - xUp;
    if (Math.abs(xDiff) > 50) {
      if (xUp < currentX) {
        this.sliderRef.current.next();
      } else {
        this.sliderRef.current.previous();
      }
      this.handleTouchEnd();
    }
  };

  handleTouchEnd = () => {
    this.setState({ currentX: undefined });
  };

  renderSlide = (currentSlide: Slide) => (
    <div className={s.currentSlide}>
      <div className="container">
        {currentSlide.title && (
          <div className="row">
            <div className="col-12 col-md-10 col-lg-10 offset-md-1">
              <div className={s.title}>
                <Reveal key={currentSlide.title} fade>
                  <Text h2 light>
                    {currentSlide.title}
                  </Text>
                </Reveal>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 col-md-5 col-lg-8 offset-md-1">
            <div className={s.attributes}>
              <div className={s.text}>
                <Reveal key={currentSlide.text} fade>
                  {currentSlide.text}
                </Reveal>
              </div>
            </div>
          </div>
          {currentSlide.content_image && currentSlide.content_image.localFile && (
            <div className="offset-md-1 offset-lg-1 col-12 col-md-4 col-lg-4">
              <div className={s.contentImage}>
                <Img
                  className={s.image}
                  fluid={
                    currentSlide.content_image.localFile.childImageSharp.fluid
                  }
                  fadeIn
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  renderIndicator = (
    slides: Slide[],
    current: number,
    next: () => void,
    previous: () => void,
    goToIndex: (index: number) => void
  ) => {
    const { color } = this.props;

    return (
      <div className={s.dots}>
        {slides.map((_slide, i) => (
          <div
            key={i}
            className={`${s.dot} ${current === i ? s.active : ''}`}
            style={{ backgroundColor: `${color}` }}
            onClick={e => {
              e.stopPropagation();
              goToIndex(i);
            }}
          />
        ))}
      </div>
    );
  };

  render() {
    const { slides, displayThumbnails } = this.props;
    const { currentSlide } = this.state;

    return (
      <div
        className={s.wrapper}
        ref={this.elementRef}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
      >
        {displayThumbnails && (
          <div className="container">
            <div className="default-margin-row">
              <div className="col-12">
                <div className="default-margin-row">
                  <div
                    className={`col-12 col-md-10 col-lg-9 offset-md-1 ${s.thumbnailsWrapper}`}
                  >
                    {slides &&
                      slides.map(({ link, thumbnail, thumbnailActive }, i) => (
                        <a className={s.thumbnail} key={i} href={link}>
                          {i === currentSlide && thumbnailActive ? (
                            <GatsbyImage
                              className={s.thumbnailImage}
                              fluid={
                                thumbnailActive &&
                                thumbnailActive.localFile.childImageSharp.fluid
                              }
                            />
                          ) : (
                            <GatsbyImage
                              className={s.thumbnailImage}
                              fluid={thumbnail.localFile.childImageSharp.fluid}
                            />
                          )}
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <BasicSlider
          slides={slides}
          height="auto"
          renderIndicator={this.renderIndicator}
          renderSlide={this.renderSlide}
          ref={this.sliderRef as any}
          onClick={this.onClickHandler}
          onSlideChange={(currentSlide: number) =>
            this.setState({ currentSlide })
          }
        />
      </div>
    );
  }
}
