import * as React from 'react';
import { graphql } from 'gatsby';
import Section from '../shared/Section';
import WTRegistrationForm from '../shared/WTRegistrationForm/WTRegistrationForm';
import * as s from './WTRegistrationPopupSection.module.scss';
import PopUp from '../shared/PopUp';
import Text from '../shared/Text';

export interface WTRegistrationPopupSectionProps {
  heading: string;
  subheading: string;
  placeholders: {
    firstName: string;
    lastName: string;
    email: string;
    submit: string;
  };
  languages: Array<{
    language: string;
    redirectUrl: string;
  }>;
  _sitemeta?: Array<{
    url: string;
  }>;
}

interface State {
  popupOpen: boolean;
}

class WTRegistrationPopupSection extends React.Component<
  WTRegistrationPopupSectionProps,
  State
> {
  constructor(props: WTRegistrationPopupSectionProps) {
    super(props);
    this.state = {
      popupOpen: true,
    };
  }

  render() {
    const { _sitemeta, heading, subheading, placeholders, languages } =
      this.props;

    return (
      <PopUp
        open={this.state.popupOpen}
        close={() => {}}
        iOS={true}
        transparent={true}
        centerContent={true}
        hideCloseButton={true}
      >
        <Section>
          <div className={s.wrapper}>
            <Text h4 teal>
              {heading}
            </Text>
            <Text h5 light>
              {subheading}
            </Text>
            <WTRegistrationForm
              placeholders={placeholders}
              _sitemeta={_sitemeta}
              languages={languages}
            />
          </div>
        </Section>
      </PopUp>
    );
  }
}

export const query = graphql`
  fragment WTRegistrationPopupFragment on WpPage_Pagefields_Page_WtRegistrationPopup {
    ... on WpPage_Pagefields_Page_WtRegistrationPopup {
      fieldGroupName
      heading
      subheading
      languages {
        language
        redirectUrl
      }
      placeholders {
        email
        firstName
        lastName
        submit
      }
    }
  }
`;

export default WTRegistrationPopupSection;
