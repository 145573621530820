// extracted by mini-css-extract-plugin
export var contentWrapper = "ContactForm-module--contentWrapper--90+2K";
export var error = "ContactForm-module--error--LMky9";
export var focus = "ContactForm-module--focus--hJApf";
export var form = "ContactForm-module--form--DqQVX";
export var information = "ContactForm-module--information--8tHSZ";
export var input = "ContactForm-module--input--m+9JX";
export var placeholder = "ContactForm-module--placeholder--5i1aO";
export var status = "ContactForm-module--status--A9uBy";
export var statusWrapper = "ContactForm-module--statusWrapper--8oswR";
export var success = "ContactForm-module--success--ADhAk";
export var textcount = "ContactForm-module--textcount--icmzc";