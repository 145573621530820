// extracted by mini-css-extract-plugin
export var button = "TextAndImage-module--button--fxJHB";
export var byline = "TextAndImage-module--byline--AQJj8";
export var caption = "TextAndImage-module--caption--dRQzA";
export var captionRow = "TextAndImage-module--captionRow--DOmD1";
export var citationImg = "TextAndImage-module--citationImg--AwJMR";
export var dashStyle = "TextAndImage-module--dashStyle--3W2z1";
export var image = "TextAndImage-module--image--cxiAC";
export var imageHigh = "TextAndImage-module--imageHigh--sHThJ";
export var imageLow = "TextAndImage-module--imageLow--zTD8L";
export var imageWrapper = "TextAndImage-module--imageWrapper--qF+Cu";
export var left = "TextAndImage-module--left--sO11O";
export var overlay = "TextAndImage-module--overlay--0cUEp";
export var overlaySmall = "TextAndImage-module--overlaySmall--jChQa";
export var right = "TextAndImage-module--right--cdDRL";
export var textExtraText = "TextAndImage-module--textExtraText--f0ovZ";
export var textExtraTitle = "TextAndImage-module--textExtraTitle--DUqr9";