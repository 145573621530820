import * as React from 'react';
import * as s from './Portfolio.module.scss';
import Text from './Text';
import { IconLink } from './components';
import { PortfolioContext } from '../../context/PortfolioContext';

export interface Props {
  department: string;
  description: string;
  linkedin: string;
  name: string;
  portfolio_type: 'website' | 'file';
  portfolio_website: string;
  portfolio_file: {
    url: {
      source_url: string;
    };
  };
  resume_type: 'website' | 'file';
  resume_website: string;
  resume_file: {
    url: {
      source_url: string;
    };
  };
  work_title: string;
  website: string;
  location: {
    name: string;
    id: number;
  }[];
  responsibility: {
    name: string;
    id: number;
  }[];
  index: number;
}

interface PortfolioState {
  showMore: boolean;
}

class Portfolio extends React.Component<Props, PortfolioState> {
  state = { showMore: false };
  static contextType = PortfolioContext;

  render() {
    const {
      department,
      description,
      linkedin,
      name,
      portfolio_type,
      portfolio_website,
      portfolio_file,
      resume_type,
      resume_website,
      resume_file,
      website,
      work_title,
      location,
      index,
    } = this.props;

    const { showMore } = this.state;

    let portfolio = '';
    if (portfolio_type === 'website' && portfolio_website) {
      portfolio = portfolio_website;
    } else if (portfolio_type === 'file' && portfolio_file) {
      portfolio = portfolio_file.url.source_url;
    }

    let resume = '';
    if (resume_type === 'website' && resume_website) {
      resume = resume_website;
    } else if (resume_type === 'file' && resume_file) {
      resume = resume_file.url.source_url;
    }

    const contentWrapperClasses = [s.contentWrapper];
    if (index % 2 === 0) {
      contentWrapperClasses.push('mr-md-2');
    } else {
      contentWrapperClasses.push('ml-md-2');
    }

    if (showMore) {
      contentWrapperClasses.push(s.open);
    }

    return (
      <div className={`col-md-6 ${s.portfolioWrapper}`}>
        <div className={contentWrapperClasses.join(' ')}>
          <Text h4>{name}</Text>
          <div className={s.title}>
            <Text h6>
              {work_title}, {department}
            </Text>
          </div>
          {location && (
            <div className={s.location}>
              {location.map(l => (
                <Text span key={l.id}>
                  {l.name}
                </Text>
              ))}
            </div>
          )}
          <div className={s.line} />
          <div className={`${s.links}`}>
            {linkedin && <IconLink icon="linkedin" link={linkedin} />}
            {website && <IconLink icon="website" link={website} />}
            {resume && <IconLink icon="resume" link={resume} />}
            {portfolio && <IconLink icon="portfolio" link={portfolio} />}
          </div>
          {description && (
            <div className={s.description}>
              <Text pSmall>
                {description.length < 120 ? (
                  description
                ) : (
                  <>
                    {showMore ? description : description.substr(0, 120)}
                    {!showMore && (
                      <>
                        ...
                        <span
                          className={s.showMore}
                          onClick={() => {
                            this.setState({ showMore: true });
                          }}
                        >
                          Read more
                        </span>
                      </>
                    )}
                    {showMore && (
                      <span
                        className={s.showLess}
                        onClick={() => {
                          this.setState({ showMore: false });
                        }}
                      >
                        Read less
                      </span>
                    )}
                  </>
                )}
              </Text>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Portfolio;
