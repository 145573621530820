import * as React from 'react';
import Hero, { Props as HeroProps } from './Hero';
import Brands, { Props as BrandsProps } from './Brands';
import News, { Props as NewsProps } from './News';
import TextAndImage, { Props as TextAndImageProps } from './TextAndImage';
import FourImagesGrid, { Props as FourImagesGridProps } from './FourImagesGrid';
import Positions, { Props as PositionsProps } from './Positions';
import Employees, { Props as EmployeesProps } from './Employees';
import InstagramBlock, { Props as InstaProps } from './InstagramBlock';
import Timeline, { Props as TimelineProps } from './Timeline';
import Citation, { Props as CitationProps } from './Citation';
import Slider, { Props as SliderProps } from './Slider';
import Offices, { Props as OfficesProps } from './Offices';
import Visit, { Props as VisitProps } from './Visit';
import Connect, { Props as ConnectProps } from './Connect';
import WpForm, { Props as WpFormProps } from './WpForm';
import ContactForm, { Props as ContactFormProps } from './ContactForm';
import FreeText, { Props as FreeTextProps } from './FreeText';
import ThreeColumnGrid, {
  Props as ThreeColumnGridProps,
} from './ThreeColumnGrid';
import Vimeo, { Props as VimeoProps } from './Vimeo';
import Portfolios, { PortfoliosProps } from './Portfolios';
import FullScreenImage, { FullScreenImageProps } from './FullScreenImage';
import Newsposts, { NewspostsProps } from './Newsposts';
import NewsletterformSection, {
  NewsletterformSectionProps,
} from './NewsletterformSection';
import WTRegistrationPopupSection, {
  WTRegistrationPopupSectionProps,
} from './WTRegistrationPopupSection';

export type Props = {
  _blocks: Props[];
  fieldGroupName: string;
} & HeroProps &
  BrandsProps &
  NewsProps &
  TextAndImageProps &
  FourImagesGridProps &
  PositionsProps &
  EmployeesProps &
  InstaProps &
  TimelineProps &
  CitationProps &
  OfficesProps &
  VisitProps &
  SliderProps &
  ConnectProps &
  ContactFormProps &
  ThreeColumnGridProps &
  VimeoProps &
  WpFormProps &
  PortfoliosProps &
  FullScreenImageProps &
  NewspostsProps &
  FreeTextProps &
  NewsletterformSectionProps &
  WTRegistrationPopupSectionProps;

/**
 * Routes each block type to its own component. Every component in /components/blocks is
 * getting the raw data from API.
 */

const Block = (props: Props) => {
  if (!props || !props.fieldGroupName) {
    return null;
  }

  switch (true) {
    case props.fieldGroupName.endsWith('Hero'):
      return <Hero {...props} />;

    case props.fieldGroupName.endsWith('Brands'):
      return <Brands {...props} />;

    case props.fieldGroupName.endsWith('News'):
      return <News {...props} />;

    case props.fieldGroupName.endsWith('TextAndImage'):
      return <TextAndImage {...props} />;

    case props.fieldGroupName.endsWith('FourImagesGrid'):
      return <FourImagesGrid {...props} />;

    case props.fieldGroupName.endsWith('Positions'):
      return <Positions {...props} />;

    case props.fieldGroupName.endsWith('Employees'):
      return <Employees {...props} />;

    // case 'WordPressAcf_instagram':
    //   return <InstagramBlock {...props} />;

    case props.fieldGroupName.endsWith('Timeline'):
      return <Timeline {...props} />;

    case props.fieldGroupName.endsWith('Citation'):
      return <Citation {...props} />;

    case props.fieldGroupName.endsWith('Slider'):
      return <Slider {...props} />;

    case props.fieldGroupName.endsWith('Offices'):
      return <Offices {...props} />;

    case props.fieldGroupName.endsWith('Visit'):
      return <Visit {...props} />;

    case props.fieldGroupName.endsWith('Connect'):
      return <Connect {...props} />;

    case props.fieldGroupName.endsWith('WpForms'):
      return <WpForm {...props} />;

    case props.fieldGroupName.endsWith('ContactForm'):
      return <ContactForm {...props} />;

    case props.fieldGroupName.endsWith('ThreeColumnGrid'):
      return <ThreeColumnGrid {...props} />;

    case props.fieldGroupName.endsWith('Vimeo'):
      return <Vimeo {...props} />;

    case props.fieldGroupName.endsWith('Portfolios'):
      return <Portfolios {...props} />;

    case props.fieldGroupName.endsWith('FullScreenImage'):
      return <FullScreenImage {...props} />;

    case props.fieldGroupName.endsWith('Newsposts'):
      return <Newsposts {...props} />;

    case props.fieldGroupName.endsWith('FreeText'):
      return <FreeText {...props} />;

    case props.fieldGroupName.endsWith('NewsletterForm'):
      return <NewsletterformSection {...props} />;

    case props.fieldGroupName.endsWith('WtRegistrationPopup'):
      return <WTRegistrationPopupSection {...props} />;

    default:
      return <div />;
  }
};

export default Block;
