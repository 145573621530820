import React, { createRef } from 'react';
import { graphql } from 'gatsby';
import * as s from './Positions.module.scss';
import Section from '../shared/Section';
import Text from '../shared/Text';
import Button from '../swace/button';
import Reveal from '../swace/reveal';
import Grid from '../shared/Grid';
import { Position, Job } from '../../types';
import PositionsMap from '../shared/PositionsMap';
import PopUp from '../shared/PopUp';
import LinkGroup from '../shared/LinkGroup';
import Script from 'react-load-script';

export interface Props {
  id: number;
  title: string;
  ingress: string;
  buttonText: string;
  buttonLink: string;
  buttonType: string;
  buttonAction: string;
  positions: Position[];
  _jobs: {
    edges: Array<{
      node: Job;
    }>;
  };
  buttons: Array<{
    buttonLink: string;
    buttonText: string;
  }>;
}

class Positions extends React.Component<Props> {
  state = {
    positionsMapOpen: false,
    previewNumber:
      this.props._jobs.edges.length >= 3 ? 3 : this.props._jobs.edges.length,
    popupHeight: 0,
    iOS: false,
  };

  componentDidMount() {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    this.handleResize();
    this.setState({ iOS: iOSSafari });
  }

  handleScriptError() {
    console.error('Error occurred when creating script tag');
  }

  handleScriptLoad() {
    // console.log('Script finished loading in Positions.tsx');
  }

  handleResize = () => {
    const currentHeight = window.innerHeight;

    if (this.state.popupHeight != currentHeight) {
      this.setState({ popupHeight: currentHeight });
    }
  };

  closePositionsMap = () => {
    const innerBody = document.getElementById('innerbody');
    innerBody &&
      innerBody.setAttribute(
        'style',
        `overflow:visible; position: initial; height: 100%`
      );
    this.setState({ positionsMapOpen: false });
  };

  openPositionMap = () => {
    const innerBody = document.getElementById('innerbody');
    const innerHeight = this.state.popupHeight;

    innerBody &&
      innerBody.setAttribute(
        'style',
        `overflow:hidden; position: relative; height: ${innerHeight}px; ${
          this.state.iOS ? `-webkit-overflow-scrolling: touch;` : ''
        }`
      );

    this.setState({ positionsMapOpen: true, popupHeight: innerHeight });
  };

  render() {
    const {
      title,
      ingress,
      buttonText,
      buttonLink,
      buttonType,
      buttonAction,
      _jobs,
      buttons,
    } = this.props;
    const { positionsMapOpen, previewNumber, popupHeight, iOS } = this.state;
    const numberOfPositions = _jobs && _jobs.edges && _jobs.edges.length;

    let buttonFunction = null;
    let showButton = true;
    if (buttonType === 'action') {
      if (buttonAction === 'see_all_positions') {
        buttonFunction = () => this.setState({ positionsMapOpen: true });
      } else if (buttonAction === 'show_more') {
        if (previewNumber >= numberOfPositions) {
          showButton = false;
        }
        buttonFunction = () => {
          this.setState({
            previewNumber: previewNumber + 3,
          });
        };
      }
    }
    return (
      <>
        <Script
          url="https://analytics.adway.ai/script/zound.js"
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
        />
        <Section sectionName="Positions">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-9 offset-md-1">
              {title && (
                <Reveal drop>
                  <Text h2>{title}</Text>
                </Reveal>
              )}
              <Reveal lift>
                <Text ingress>{ingress}</Text>
                {buttons && (
                  <LinkGroup
                    buttons={buttons.map((e) => ({
                      buttonText: e.buttonText,
                      buttonLink: e.buttonLink,
                      buttonFunction: e.buttonLink
                        ? null
                        : () => this.openPositionMap(),
                      button_id: e.buttonText,
                    }))}
                  />
                )}
              </Reveal>
            </div>
          </div>
          <div className={s.items}>
            <Grid
              items={_jobs.edges.slice(0, previewNumber).map((n) => ({
                title: n.node.attributes.title,
                image: n.node.attributes.picture
                  ? n.node.attributes.picture.standard
                  : undefined,
                tags: [
                  n.node.relationships.department.attributes.name,
                  n.node.relationships.location.attributes.city,
                ],
                href: `/positions/${n.node.links.relativeUrl}`,
                key: n.node.id,
              }))}
            />
          </div>
          <div className="row">
            <div className="col-12 col-sm-8 col-md-6 col-lg-4 offset-sm-2 offset-md-3 offset-lg-4 d-flex justify-content-center">
              <Reveal lift>
                {buttonType === 'link' && (
                  <Button href={buttonLink} dark className={s.button}>
                    {buttonText}
                  </Button>
                )}
                {buttonType === 'action' && showButton && (
                  <Button onClick={buttonFunction} filled className={s.button}>
                    {buttonText}
                  </Button>
                )}
              </Reveal>
            </div>
          </div>
        </Section>
        <PopUp
          open={positionsMapOpen}
          close={this.closePositionsMap}
          height={popupHeight}
          iOS={iOS}
        >
          <PositionsMap jobs={_jobs.edges} />
        </PopUp>
      </>
    );
  }
}

export const query = graphql`
  fragment PositionsBlockFragment on WpPage_Pagefields_Page_Positions {
    ... on WpPage_Pagefields_Page_Positions {
      fieldGroupName
      title
      ingress
      buttons {
        buttonText
        buttonLink {
          ... on WpPost {
            uri
          }
          ... on WpPage {
            uri
          }
          ... on WpNewsItem {
            uri
          }
          ... on WpPerson {
            uri
          }
          ... on WpOffice {
            uri
          }
          ... on WpAnnualReport {
            uri
          }
        }
      }
      buttonText
      buttonType
      buttonLink {
        ... on WpPost {
          uri
        }
        ... on WpPage {
          uri
        }
        ... on WpNewsItem {
          uri
        }
        ... on WpPerson {
          uri
        }
        ... on WpOffice {
          uri
        }
        ... on WpAnnualReport {
          uri
        }
      }
      buttonAction
    }
  }
  fragment TeamtailorBlockFragment on Query {
    allJob(
      filter: { id: { ne: "mockJob" } }
      sort: { fields: attributes___createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          links {
            relativeUrl
          }
          attributes {
            title
            body
            picture {
              standard
            }
          }
          relationships {
            department {
              attributes {
                name
              }
            }
            location {
              attributes {
                city
                lat
                long
              }
            }
            questions {
              id
              attributes {
                title
                multiple
                alternatives {
                  id
                  title
                }
                startWith
                endWith
                unit
                singleLine
                description
              }
            }
          }
        }
      }
    }
  }
`;

export default Positions;
