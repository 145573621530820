// extracted by mini-css-extract-plugin
export var backgroundImage = "GridItem-module--backgroundImage--BFlmM";
export var image = "GridItem-module--image--SiW8x";
export var newsCorner = "GridItem-module--newsCorner--3qPWa";
export var newsCornerWrapper = "GridItem-module--newsCornerWrapper--e2KV1";
export var newsItem = "GridItem-module--newsItem--8tPG8";
export var newsItemContainer = "GridItem-module--newsItemContainer--2LRFO";
export var newsItemLabel = "GridItem-module--newsItemLabel--jFuRP";
export var newsItemText = "GridItem-module--newsItemText--u7uxt";
export var placeholderImage = "GridItem-module--placeholderImage--eMvLH";
export var plus = "GridItem-module--plus--EruRf";
export var wrapper = "GridItem-module--wrapper--EJnTp";