import * as React from 'react';

interface Props {
  to: number;
}

interface State {
  current: number;
  counterId?: any;
}

class CountUp extends React.Component<Props, State> {
  state = {
    current: 0,
  };

  add = () => {
    const { current, counterId } = this.state;
    const { to } = this.props;
    const nextCurrent = current + to / 50;

    this.setState({ current: nextCurrent > to ? to : nextCurrent });

    if (current >= to) {
      clearInterval(counterId);
    }
  };

  componentDidMount() {
    const counterId = setInterval(this.add, 10);
    this.setState({ counterId });
  }

  render() {
    const { current } = this.state;

    return <div>{Math.floor(current)}</div>;
  }
}

export default CountUp;
