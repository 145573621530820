// extracted by mini-css-extract-plugin
export var arrow = "WTRegistrationForm-module--arrow--puddh";
export var dropdown = "WTRegistrationForm-module--dropdown--DdwPE";
export var dropdownLabel = "WTRegistrationForm-module--dropdownLabel--2VLCf";
export var dropdownList = "WTRegistrationForm-module--dropdownList--9vMwU";
export var errorText = "WTRegistrationForm-module--errorText--R9FNH";
export var fade_in = "WTRegistrationForm-module--fade_in--4RqlE";
export var inputField = "WTRegistrationForm-module--inputField--wKe0Q";
export var invalid = "WTRegistrationForm-module--invalid--KDde2";
export var open = "WTRegistrationForm-module--open--ukvj+";
export var show = "WTRegistrationForm-module--show--HPu2c";