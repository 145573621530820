import * as React from 'react';
import { graphql } from 'gatsby';
import Section from '../shared/Section';
import Newsletterform from '../shared/NewsletterForm/Newsletterform';
import Reveal from '../swace/reveal';
import Text from '../shared/Text';

export interface NewsletterformSectionProps {
  heading: string;
  emailHeading: string;
  submitButton: string;
  _sitemeta: Array<{
    url: string;
  }>;
}

interface State {
  email: string;
  errorText: string;
  successText: string;
}

class NewsletterformSection extends React.Component<
  NewsletterformSectionProps,
  State
> {
  constructor(props: NewsletterformSectionProps) {
    super(props);
  }

  render() {
    const { heading, emailHeading, submitButton } = this.props;
    return (
      <Section slimTop wideDesktop>
        <Reveal lift>
          <div className="mb-4">
            <Text h4>{heading}</Text>
          </div>
          <Newsletterform
            emailHeading={emailHeading}
            submitButton={submitButton}
            _sitemeta={this.props._sitemeta}
          />
        </Reveal>
      </Section>
    );
  }
}

export const query = graphql`
  fragment NewsletterformBlockFragment on WpPage_Pagefields_Page_NewsletterForm {
    ... on WpPage_Pagefields_Page_NewsletterForm {
      fieldGroupName
      heading
      emailHeading
      submitButton
    }
  }
`;
export default NewsletterformSection;
