import * as React from 'react';
import * as s from './PopUp.module.scss';

interface Props {
  open?: boolean;
  close: Function;
  height?: number;
  iOS: boolean;
  transparent?: boolean;
  centerContent?: boolean;
  hideCloseButton?: boolean;
}

class PopUp extends React.Component<Props> {
  render() {
    const {
      children,
      close,
      open,
      height,
      iOS,
      transparent,
      centerContent,
      hideCloseButton = false,
    } = this.props;
    let style;
    if (height > 0 && iOS) {
      style = { height: height };
    }

    return (
      <div
        className={`${s.popup} ${centerContent ? s.centerContent : ''}  ${
          transparent ? s.transparent : ''
        } ${open ? s.open : ''}`}
        style={style}
      >
        <div className={`${s.wrapper} ${transparent ? s.transparent : ''}`}>
          {!hideCloseButton && (
            <button
              className={`${s.burger} ${transparent ? s.transparent : ''} ${
                centerContent ? s.centerContent : ''
              }`}
              onClick={e => close()}
            >
              <span className={s.menuTitle}>Close</span>
              <div>
                <div className={s.bar} />
                <div className={s.bar} />
              </div>
            </button>
          )}
          {children}
        </div>
      </div>
    );
  }
}

export default PopUp;
