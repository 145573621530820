import * as React from 'react';
import * as s from './PositionsMap.module.scss';
import { Job } from '../../types';
import Section from '../shared/Section';
import locationPin from '../../img/location_pin.svg';
import arrow from '../../img/arrow-forward.svg';
import { DynamicLink } from './components';
import WorldMapImage from '../../img/world-map/world-map-small.png';

interface Props {
  jobs: Array<{
    node: Job;
  }>;
}

interface PositionProps {
  job: Job;
}

class Position extends React.Component<PositionProps> {
  state = {
    open: false,
  };

  render() {
    const { job } = this.props;
    const { open } = this.state;

    return (
      <div className={s.positionContainer}>
        <div
          className={s.heading}
          onClick={() =>
            this.setState({
              open: !open,
            })
          }
        >
          <p>{job.attributes.title}</p>
          <img
            src={arrow}
            className={[s.arrow, open ? s.open : ''].join(' ')}
          />
        </div>
        <div className={[open ? s.open : '', s.toggleOpen].join(' ')}>
          <p>{job.relationships.department.attributes.name}</p>

          <DynamicLink to={`/positions/${job.links.relativeUrl}`}>
            <p className={s.apply}>Apply</p>
          </DynamicLink>
        </div>
      </div>
    );
  }
}

class PositionsMap extends React.Component<Props> {
  state = {
    currentLocation: 'Stockholm',
  };

  locations = [
    {
      name: 'Stockholm',
      location: {
        // percentage
        left: 54.5,
        bottom: 60,
      },
      positions: this.props.jobs.filter(
        (e) => e.node.relationships.location.attributes.city === 'Stockholm'
      ),
    },
    {
      name: 'New York',
      location: {
        left: 24,
        bottom: 47,
      },
      positions: this.props.jobs.filter(
        (e) => e.node.relationships.location.attributes.city === 'New York'
      ),
    },
    {
      name: 'Paris',
      location: {
        left: 52,
        bottom: 53,
      },
      positions: this.props.jobs.filter(
        (e) => e.node.relationships.location.attributes.city === 'Paris'
      ),
    },
    {
      name: 'Hong Kong',
      location: {
        left: 81,
        bottom: 40,
      },
      positions: this.props.jobs.filter(
        (e) => e.node.relationships.location.attributes.city === 'Hong Kong'
      ),
    },
    {
      name: 'Shenzen',
      location: {
        left: 79,
        bottom: 40,
      },
      positions: this.props.jobs.filter(
        (e) => e.node.relationships.location.attributes.city === 'Shenzen'
      ),
    },
    {
      name: 'London',
      location: {
        left: 50,
        bottom: 55,
      },
      positions: this.props.jobs.filter(
        (e) => e.node.relationships.location.attributes.city === 'London'
      ),
    },
    {
      name: 'Amsterdam',
      location: {
        left: 53,
        bottom: 56,
      },
      positions: this.props.jobs.filter(
        (e) => e.node.relationships.location.attributes.city === 'Amsterdam'
      ),
    },
  ];

  render() {
    const { currentLocation } = this.state;
    return (
      <Section slimTop>
        <div className={s.wrapper}>
          <p className={`${s.topText} d-none d-md-block`}>
            Click to see open position on different offices
          </p>
          <div className={`row d-flex align-items-start ${s.mainContainer}`}>
            {/*
            The Map
          */}
            <div className={`col-md-8 order-md-2`}>
              <div className={s.locationMapContainer}>
                <img src={WorldMapImage} className={s.map} />
                {this.locations.map(
                  function (e, i) {
                    const active = e.name === currentLocation;
                    return (
                      <div
                        key={i}
                        className={s.pinAndBubble}
                        style={{
                          bottom: `${e.location.bottom}%`,
                          left: `${e.location.left}%`,
                          zIndex: active ? 2 : 1,
                        }}
                      >
                        {active && (
                          <div className={`${s.bubble} d-none d-md-flex`}>
                            <p className={s.city}>{e.name}</p>
                            <p className={s.headquarters}>Headquarters</p>
                            <p className={s.nPositions}>
                              {e.positions.length} Positions
                            </p>
                          </div>
                        )}
                        <img
                          src={locationPin}
                          className={[s.pin, active ? s.active : ''].join(' ')}
                          onClick={() => {
                            this.setState({ currentLocation: e.name });
                          }}
                          style={{
                            pointerEvents: active ? 'none' : 'auto',
                          }}
                        />
                      </div>
                    );
                  }.bind(this)
                )}
              </div>
              <div
                className={`${s.cityLinkBlock} d-lg-flex justify-content-lg-center`}
              >
                {this.locations.map(
                  function (l, i) {
                    const active = l.name === currentLocation;
                    return (
                      <p
                        key={i}
                        className={[s.cityLink, active ? s.active : ''].join(
                          ' '
                        )}
                        onClick={() =>
                          this.setState({ currentLocation: l.name })
                        }
                      >
                        {l.name}
                      </p>
                    );
                  }.bind(this)
                )}
              </div>
            </div>
            {/*
            The List 
          */}
            <div
              className={`col-md-4 order-md-1 ${s.openPositionsListContainer}`}
            >
              <p className={s.openPositionsHeading}>
                Open positions
                <br />
                {currentLocation}
              </p>

              {this.locations
                .find((e) => e.name === currentLocation)
                .positions.map((position) => (
                  <Position job={position.node} key={position.node.id} />
                ))}
            </div>
          </div>
        </div>
      </Section>
    );
  }
}

export default PositionsMap;
