import * as React from 'react';
import * as s from './LinkGroup.module.scss';
import Button from '../swace/button';
import { getCdnEndpoint } from '~libs/utils';

interface Props {
  buttons: Array<{
    buttonText: string;
    buttonLinkType?: string;
    buttonLink?: {
      uri: string;
    };
    buttonLinkExternalUrl?: string;
    buttonLinkFile?: {
      link?: string;
      mediaItemUrl?: string;
    };
    buttonFunction?: () => void;
    buttonId?: string;
  }>;
  thickBottom?: boolean;
  slimBottom?: boolean;
  activeId?: string; // the id of the active button, should match buttonId for the active one.
}

const isExternalUrl = (linkType) =>
  linkType === 'external' || linkType === 'file';

const returnLink = (button) => {
  let link = null;

  if (button.buttonLinkType) {
    switch (button.buttonLinkType) {
      case 'internal':
        link = button.buttonLink.uri;
        break;
      case 'external':
        link = button.buttonLinkExternal;
        break;
      case 'file':
        link = getCdnEndpoint(button.buttonLinkFile.mediaItemUrl);
        break;

      default:
        link = button.buttonLink.uri;
        break;
    }
  } else {
    link = button.buttonLink && button.buttonLink.uri;
  }
  return link;
};

const LinkGroup = ({ buttons, thickBottom, slimBottom, activeId }: Props) => {
  if (thickBottom && slimBottom) {
    throw new Error('Cannot set both thickBottom and smallBottom');
  }

  return (
    <div
      className={[
        s.buttons,
        thickBottom ? s.thickBottom : '',
        slimBottom ? s.slimBottom : '',
      ].join(' ')}
    >
      {buttons.map((button, i) => (
        <div className={s.button} key={i}>
          <Button
            slim
            black={activeId && activeId !== button.buttonId}
            dark={!activeId || activeId === button.buttonId}
            underlined={activeId && activeId === button.buttonId}
            href={returnLink(button)}
            isExternalUrl={isExternalUrl(button.buttonLinkType)}
            onClick={button.buttonFunction}
          >
            {button.buttonText}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default LinkGroup;
