import * as React from 'react';
import * as s from './BasicSlider.module.scss';
import { Image } from '../../../types';
import BackgroundImage from '../../swace/gatsby-background-image';

interface Slide {
  image: Image;
  link?: Location;
}

export interface BasicSliderProps {
  ref?: (ref: BasicSlider) => void;
  slides: Slide[];
  height: 'full' | 'auto';
  renderIndicator?(
    slides: Slide[],
    current: number,
    next: () => void,
    previous: () => void,
    goToIndex: (index: number) => void
  );
  renderSlide(currentSlide: Slide): void;
  onSlideChange?(currentSlide: number): void;
  onClick?: () => void;
}

interface State {
  currentSlide: number;
}

export class BasicSlider extends React.Component<BasicSliderProps, State> {
  state = {
    currentSlide: 0,
  };

  componentDidMount() {
    const { onSlideChange } = this.props;
    if (onSlideChange) {
      onSlideChange(0);
    }
  }

  next = () => {
    const { currentSlide } = this.state;
    const { slides, onSlideChange } = this.props;

    const currentSlideIndex =
      slides.length > currentSlide + 1 ? currentSlide + 1 : 0;

    this.setState({
      currentSlide: currentSlideIndex,
    });

    if (onSlideChange) {
      onSlideChange(currentSlideIndex);
    }
  };

  previous = () => {
    const { currentSlide } = this.state;
    const { slides, onSlideChange } = this.props;

    const currentSlideIndex =
      currentSlide > 0 ? currentSlide - 1 : slides.length - 1;

    this.setState({
      currentSlide: currentSlideIndex,
    });

    if (onSlideChange) {
      onSlideChange(currentSlideIndex);
    }
  };

  goToIndex = (index: number) => {
    const { slides, onSlideChange } = this.props;

    if (index < slides.length && index >= 0) {
      this.setState({ currentSlide: index });
    }

    if (onSlideChange) {
      onSlideChange(index);
    }
  };

  render() {
    const {
      slides,
      renderIndicator,
      renderSlide,
      onClick,
      height,
    } = this.props;
    const { currentSlide } = this.state;

    let wrapperClasses = [s.wrapper];
    if (height === 'full') {
      wrapperClasses.push(s.full);
    } else {
      wrapperClasses.push(s.auto);
    }

    return (
      <div className={wrapperClasses.join(' ')} onClick={onClick}>
        <div className={s.slides}>
          {slides &&
            slides.map((slide, i) => (
              <div
                key={i}
                className={`${s.slide} ${i === currentSlide ? s.active : ''} ${
                  i === currentSlide ? s.previous : ''
                }`}
              >
                {slide.image && <BackgroundImage image={slide.image} />}
              </div>
            ))}
        </div>
        {renderSlide(slides[currentSlide])}
        {renderIndicator &&
          renderIndicator(
            slides,
            currentSlide,
            this.next,
            this.previous,
            this.goToIndex
          )}
      </div>
    );
  }
}
