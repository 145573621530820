import * as React from 'react';
import * as s from './Progress.module.scss';

type Props = {
  sqSize?: number;
  percentage?: number;
  strokeWidth?: number;
  reset?: boolean;
  color?: string;
  type?: 'spinner' | 'bar';
  percentageFontStyle?: any;
};

class Progress extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  renderSpinner() {
    const {
      sqSize = 30,
      percentage = 25,
      strokeWidth = 2,
      reset,
      color,
    } = this.props;

    const radius = (sqSize - strokeWidth) / 2;
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - (dashArray * percentage) / 100;

    return (
      <svg width={sqSize} height={sqSize} viewBox={viewBox}>
        <circle
          className={s.circleBackground}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          className={`${s.circleProgress} ${reset ? s.reset : ''}`}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
            stroke: color,
          }}
        />
      </svg>
    );
  }

  renderBar() {
    const {
      percentage,
      strokeWidth = 5,
      color,
      percentageFontStyle,
    } = this.props;

    return (
      <div className={s.barWrapper}>
        <div className={s.bar} style={{ height: `${strokeWidth}px` }}>
          <div
            className={s.barActive}
            style={{ backgroundColor: color, width: `${percentage}%` }}
          />
        </div>
        <div className={s.percentage} style={{ ...percentageFontStyle }}>
          {percentage}%
        </div>
      </div>
    );
  }

  render() {
    const { type = 'spinner' } = this.props;

    if (type === 'bar') {
      return this.renderBar();
    }

    return this.renderSpinner();
  }
}

export default Progress;
