import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import Section from '../shared/Section';
import * as s from './Portfolios.module.scss';
import Text from '~components/shared/Text';
import Portfolio from '~components/shared/Portfolio';
import Button from '~components/swace/button';
import {
  PortfolioContextProvider,
  PortfolioContext,
} from '../../context/PortfolioContext';
import { PortfolioFilters } from '~components/shared/components/';
import InputField from '~components/shared/ApplyForm/InputField';
import { ApiClient } from '~libs/api';

export interface PortfoliosProps {
  title: string;
}

interface PortfoliosState {
  locked: boolean;
  enteredPassword: string;
  error: boolean;
  errorMsg: string;
  showPasswordForm: boolean;
  passwordStatusMsg: string;
  enteredEmail: string;
}

class PortfoliosComponent extends React.Component<
  PortfoliosProps,
  PortfoliosState
> {
  state = {
    locked: true,
    enteredPassword: '',
    error: false,
    errorMsg: '',
    showPasswordForm: false,
    enteredEmail: '',
    passwordStatusMsg: '',
  };
  client = new ApiClient();

  onSubmit = (e) => {
    e.preventDefault();
    const { enteredPassword } = this.state;

    const formData = new FormData();
    formData.append('password', enteredPassword);

    this.login(formData);
  };

  async login(formData) {
    const response = await this.client.portfolioPageLogin(formData);
    let errorMsg = '';
    switch (response.status) {
      case 200: {
        this.setState({ locked: false });
        break;
      }
      case 403: {
        errorMsg = 'Wrong password';
        this.setState({ error: true, errorMsg });
        break;
      }
      case 422: {
        errorMsg = 'Password empty';
        this.setState({ error: true, errorMsg });
        break;
      }
      default: {
        errorMsg = 'Something went wrong';
        this.setState({ error: true, errorMsg });
        break;
      }
    }
  }

  async request_password(email: string) {
    const obj = { email };
    const json = JSON.stringify(obj);
    const response = await this.client.portfolioRequestPassword(json);
    let statusMsg = '';
    switch (response.status) {
      case 200: {
        statusMsg = 'Password request sent';
        this.setState({ showPasswordForm: false });
        this.setState({ passwordStatusMsg: statusMsg });
        break;
      }
      case 422: {
        statusMsg = 'Email is not valid';
        this.setState({ passwordStatusMsg: statusMsg });
        break;
      }
      default: {
        statusMsg = 'Something went wrong, please try again';
        this.setState({ passwordStatusMsg: statusMsg });
        break;
      }
    }
  }

  render() {
    const { title } = this.props;
    const {
      locked,
      enteredPassword,
      error,
      errorMsg,
      showPasswordForm,
      enteredEmail,
      passwordStatusMsg,
    } = this.state;

    if (locked) {
      return (
        <Section sectionName="Portfolios">
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <InputField
                name="password"
                type="password"
                required
                dark
                label="Password"
                onChange={(e) =>
                  this.setState({
                    enteredPassword: e.target.value,
                    error: false,
                  })
                }
                value={enteredPassword}
              />
            </div>
            {error && (
              <div className="row">
                <div className={`col-12 col-md-6 ${s.error}`}>{errorMsg}</div>
              </div>
            )}
            <div className="row">
              <div className="col-12 col-md-6">
                <Button
                  filled
                  type="submit"
                  wide
                  onClick={() => {}}
                  className={s.accessPortfoliosButton}
                >
                  Enter talent directory
                </Button>
              </div>
            </div>
          </form>
          {!showPasswordForm && passwordStatusMsg === '' && (
            <Button
              dark
              slim
              onClick={() => {
                this.setState({ showPasswordForm: true });
              }}
            >
              Request password
            </Button>
          )}
          {showPasswordForm && (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.request_password(enteredEmail);
                }}
              >
                <div className="row">
                  <InputField
                    name="email"
                    type="email"
                    dark
                    required
                    label="Email"
                    onChange={(e) =>
                      this.setState({
                        enteredEmail: e.target.value,
                        passwordStatusMsg: '',
                      })
                    }
                    value={enteredEmail}
                  />
                </div>
                <Button
                  filled
                  wide
                  type="submit"
                  onClick={() => {}}
                  className={s.accessPortfoliosButton}
                >
                  Request Password
                </Button>
              </form>
            </>
          )}
          {passwordStatusMsg && (
            <div className="row">
              <div className={`col-12 col-md-6`}>{passwordStatusMsg}</div>
            </div>
          )}
        </Section>
      );
    } else {
      return (
        <PortfolioContext.Consumer>
          {(context) => (
            <Section sectionName="Portfolios">
              <div className="row">
                <div className={`col-md-10 col-11 ${s.centered}`}>
                  <div className="row">
                    <Text h3>{title}</Text>
                  </div>
                  <PortfolioFilters />
                  <div className={`row`}>
                    {context.portfolios.map((node, i) => (
                      <Portfolio
                        {...node.acf}
                        key={node.id}
                        location={node.location}
                        index={i}
                      />
                    ))}
                  </div>
                </div>
              </div>
              {context.hiddenContent && (
                <Button
                  wide
                  filled
                  onClick={context.showContent}
                  className={s.button}
                >
                  <Text h6 light>
                    Show me more
                  </Text>
                </Button>
              )}
            </Section>
          )}
        </PortfolioContext.Consumer>
      );
    }
  }
}

class PortfoliosSection extends React.Component<PortfoliosProps> {
  render() {
    return (
      <StaticQuery
        query={portfoliosQuery}
        render={(data) => {
          return (
            <PortfolioContextProvider nodes={data.allWpPortfolio.nodes}>
              <PortfoliosComponent {...this.props} />
            </PortfolioContextProvider>
          );
        }}
      />
    );
  }
}

export const query = graphql`
  fragment PortfoliosBlockFragment on WpPage_Pagefields_Page_Portfolios {
    ... on WpPage_Pagefields_Page_Portfolios {
      fieldGroupName
      title
    }
  }
`;

const portfoliosQuery = graphql`
  query {
    allWpPortfolio(filter: { title: { ne: "SWACE_PLACEHOLDER" } }) {
      nodes {
        portfolio {
          department
          description
          linkedin
          name
          website
          workTitle
          portfolioType
          portfolioWebsite
          portfolioFile {
            sourceUrl
          }
          resumeType
          resumeWebsite
          resumeFile {
            sourceUrl
          }
        }
        locations {
          nodes {
            name
            id
          }
        }
        responsibilities {
          nodes {
            name
            id
          }
        }
        id
      }
    }
  }
`;

export default PortfoliosSection;
