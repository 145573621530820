import * as React from 'react';
import { Image } from '../../types';
import * as s from './ThumbnailCarousel.module.scss';
import Img from 'gatsby-image';

interface CarouselThumbnail {
  image: Image;
  text: React.ReactNode;
}

interface Props {
  activeThumbnail: number;
  onClick: Function;
  thumbnails: CarouselThumbnail[];
}

class ThumbnailCarousel extends React.Component<Props> {
  state = {
    thumbnailGroupIndex: 1, // Can be 0, 1 or 2
    windowWidth: 1000,
  };

  componentDidMount = () => {
    this.adjustWindowWidth();
    window.addEventListener('resize', this.adjustWindowWidth, true);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.adjustWindowWidth, true);
  };

  adjustWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  // If the slider has carousel behavior on the thumbnails
  gotoThumbnailIndex = (index: number) => {
    this.setState({ thumbnailGroupIndex: index });
  };

  // Takes the index and the group index of the thumbnail, returns boolean true if visible false otherwise.
  thumbnailIsVisible = (i, g, numThumbnails) => {
    const { thumbnailGroupIndex } = this.state;
    const { activeThumbnail, onClick } = this.props;

    if (
      g === thumbnailGroupIndex &&
      activeThumbnail - 4 <= i &&
      i <= activeThumbnail + 4
    ) {
      return true;
    }
    if (
      // left
      thumbnailGroupIndex === (g + 1) % 3 &&
      activeThumbnail <= 4 &&
      numThumbnails - (4 - activeThumbnail) <= i
    ) {
      return true;
    }
    if (
      // right
      thumbnailGroupIndex === (g + 2) % 3 &&
      i + numThumbnails <= activeThumbnail + 4
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { thumbnailGroupIndex, windowWidth } = this.state;
    const { onClick, thumbnails, activeThumbnail } = this.props;
    const windowXL = windowWidth >= 1500;
    const thumbnailWidth = !windowXL ? 200 : (200 / 1500) * windowWidth;
    const numThumbnails = thumbnails.length;

    return (
      <div className={s.thumbnailsWrapper}>
        {/* Frame around the center active thumbnail */}
        <div
          className={s.activeThumbnailFrame}
          style={{ left: `calc(50% - ${thumbnailWidth / 4}px` }}
        >
          <div
            className={s.activeThumbnailFrameSquare}
            style={{ left: 0, top: 0 }}
          >
            <div
              className={s.activeThumbnailFrameSquareInner}
              style={{
                left: !windowXL ? '4px' : 'calc(100vw * 4px/1500)',
                top: !windowXL ? '4px' : 'calc(100vw * 4px/1500)',
              }}
            />
          </div>
          <div
            className={s.activeThumbnailFrameSquare}
            style={{ right: 0, bottom: 0 }}
          >
            <div
              className={s.activeThumbnailFrameSquareInner}
              style={{
                right: !windowXL ? '4px' : 'calc(100vw * 4px/1500)',
                bottom: !windowXL ? '4px' : 'calc(100vw * 4px/1500)',
              }}
            />
          </div>
        </div>
        {[0, 1, 2].map(g => (
          <div
            key={g}
            className={`${s.thumbnails} ${s.centerActive}`}
            style={{
              transform: `translateX(calc(${
                thumbnailGroupIndex === g
                  ? '0%' // center
                  : thumbnailGroupIndex === (g + 1) % 3
                  ? '-100%' // left
                  : '100%' // right
              } + ${-(
                thumbnailWidth / 2 +
                thumbnailWidth * activeThumbnail
              )}px))`,
            }}
          >
            {thumbnails.map(({ image, text }, i) => (
              <button
                className={s.thumbnail}
                key={i}
                onClick={() => {
                  onClick(i);
                  this.gotoThumbnailIndex(g);
                }}
                style={{
                  opacity: this.thumbnailIsVisible(i, g, numThumbnails) ? 1 : 0,
                }}
              >
                <div className={s.thumbnailImageContainer}>
                  {image && (
                    <Img
                      className={s.thumbnailImage}
                      fluid={image.localFile.childImageSharp.fluid}
                    />
                  )}
                </div>
                {text}
              </button>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default ThumbnailCarousel;
