import * as React from 'react';
import Text from './Text';
import { Image } from '../../types';
import GatsbyImage from 'gatsby-image';
import { DynamicLink } from './components';
import * as s from './WpFormGridItem.module.scss';

interface Props {
  link: string;
  heading: string;
  image: Image;
  subHeading: string;
}

class WpFormGridItem extends React.Component<Props> {
  render() {
    const { link, heading, image, subHeading } = this.props;
    const gridItemClasses = [s.gridItem];
    if (heading !== '' && !subHeading && !image) {
      gridItemClasses.push(s.title);
    }

    return (
      <DynamicLink to={link}>
        <div className={gridItemClasses.join(' ')}>
          {image && (
            <div className={s.imageWrapper}>
              <GatsbyImage
                className={s.gridImage}
                fluid={image.localFile.childImageSharp.fluid}
              />
            </div>
          )}
          <Text h4>{heading}</Text>
          <Text h6>{subHeading}</Text>
        </div>
      </DynamicLink>
    );
  }
}

export default WpFormGridItem;
