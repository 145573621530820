// extracted by mini-css-extract-plugin
export var contentWrapper = "Portfolio-module--contentWrapper--2jwXK";
export var description = "Portfolio-module--description--t4ydw";
export var line = "Portfolio-module--line--n7Kgg";
export var links = "Portfolio-module--links--4VwxT";
export var location = "Portfolio-module--location--RT8PA";
export var open = "Portfolio-module--open--JK6tT";
export var portfolioWrapper = "Portfolio-module--portfolioWrapper--dT2KI";
export var showLess = "Portfolio-module--showLess--BI3al";
export var showMore = "Portfolio-module--showMore--22w27";
export var title = "Portfolio-module--title--qQ26Q";