import * as React from 'react';
import { graphql } from 'gatsby';
import * as s from './FreeText.module.scss';
import Section from '../shared/Section';
import Reveal from '../swace/reveal';

export interface Props {
  fieldGroupName: string;
  text: string;
}

class FreeText extends React.Component<Props> {
  render() {
    const { text } = this.props;

    return (
      <Section>
        <div className="row">
          <div className="col-12">
            <Reveal lift>
              <div className={`${s.wrapper}`}>
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </Reveal>
          </div>
        </div>
      </Section>
    );
  }
}

export const query = graphql`
  fragment FreeTextBlockFragment on WpPage_Pagefields_Page_FreeText {
    ... on WpPage_Pagefields_Page_FreeText {
      fieldGroupName
      text
    }
  }
`;

export default FreeText;
