import * as React from 'react';
import ReactDOM from 'react-dom';

interface Props {
  link: string;
  backendUrl: string;
}

interface State {
  iFrameHeight: number;
}

class WpFormIframe extends React.Component<Props, State> {
  componentDidMount() {
    const { backendUrl } = this.props;
    const obj = ReactDOM.findDOMNode(this);

    window.addEventListener('message', (event) => {
      if (~event.origin.indexOf(backendUrl)) {
        obj.style.height = event.data + 200 + 'px';
      } else {
        return;
      }
    });
  }

  render() {
    const { backendUrl, link } = this.props;

    return <iframe src={backendUrl + link} />;
  }
}
export default WpFormIframe;
