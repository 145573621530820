import * as React from 'react';
import { graphql } from 'gatsby';
import { Employee } from '~types';
import Section from '~components/shared/Section';
import Text from '~components/shared/Text';
import Reveal from '~components/swace/reveal';
import * as s from './Employees.module.scss';
import { ArrowSlider, ScrollSlider } from '~components/shared/slider';
import colors from '~colors';

export interface Props {
  fieldGroupName: string;
  title: string;
  text: string;
  _employees: { edges: Array<{ node: Employee }> };
}

const thumbnailText = (name, title, location) => (
  <>
    <div className={s.name}>
      <Text h6>{name}</Text>
    </div>
    <div className={s.title}>
      <Text pSmall>{title}</Text>
    </div>
    <div className={s.title}>
      <Text pSmall>{location}</Text>
    </div>
  </>
);

class Employees extends React.Component<Props> {
  render() {
    const { title, text, _employees } = this.props;

    const slides =
      _employees &&
      _employees.edges.map((employee) => ({
        image: employee.node.person.image,
        title: employee.node.person.name,
        text: employee.node.person.title,
        link: employee.node.uri,
      }));

    return (
      <Section wide sectionName="Employees">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <Reveal drop>
                <div className={s.heading}>
                  <Text h2>{title}</Text>
                </div>
                <div>
                  <Text ingress>{text}</Text>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
        <div className={s.sliderWrapper}>
          <div className="container-fluid">
            <Reveal lift>
              <div className="row">
                <div className="col-12">
                  <ScrollSlider
                    slides={slides}
                    color={colors.WHITE}
                    time={'4'}
                    displayThumbnails={false}
                  />
                </div>
              </div>
            </Reveal>
          </div>
        </div>
      </Section>
    );
  }
}

export const query = graphql`
  fragment EmployeesBlockFragment on WpPage_Pagefields_Page_Employees {
    ... on WpPage_Pagefields_Page_Employees {
      fieldGroupName
      title
      text
    }
  }
`;

export default Employees;
