import * as React from 'react';
import { Image } from '../../../types';
import * as s from './ArrowSlider.module.scss';
import Text from '../../shared/Text';
import { BasicSlider } from './BasicSlider';
import Icon from '../../swace/icon';
import Progress from '../../swace/progress';
import Button from '../../swace/button';
import GatsbyImage from 'gatsby-image';
import ThumbnailCarousel from '../ThumbnailCarousel';

interface Slide {
  image: Image;
  title: string;
  text?: string;
  link?: string;
  thumbnail?: Image;
  thumbnailActive?: Image;
  thumbnailText?: React.ReactNode;
}

export interface ArrowSliderProps {
  elements: Slide[];
  thumbnailCarousel: boolean;
  slideTime: number;
}

interface State {
  currentIndex: number;
  time: number;
  timerId: any;
}

export class ArrowSlider extends React.Component<ArrowSliderProps, State> {
  public static defaultProps = {
    elements: [],
    thumbnailCarousel: false,
    slideTime: 8,
  };

  state = {
    currentIndex: 0,
    time: 0,
    timerId: null,
  };

  private sliderRef = React.createRef<BasicSlider>();

  renderSlide = ({ text }: Slide & { id: number }) => (
    <div>
      <div className={s.slideText}>
        <Text light ingress>
          {text}
        </Text>
      </div>
    </div>
  );

  onSlideChange = (index: number) => {
    this.setState({ currentIndex: index });
  };

  setTimer = () => {
    const { slideTime } = this.props;
    const { time } = this.state;

    if (time === slideTime) {
      this.sliderRef.current.next();
    }

    this.setState({ time: time < slideTime ? time + 1 : 0 });
  };

  componentDidMount() {
    const timerId = setInterval(this.setTimer, 1000);

    this.setState({ timerId });
  }

  componentWillUnmount() {
    const { timerId } = this.state;

    clearInterval(timerId);
  }

  goToIndex = (index: number) => {
    this.sliderRef.current.goToIndex(index);
    this.setState({ time: 0 });
  };

  next = () => {
    this.sliderRef.current.next();
    this.setState({ time: 0 });
  };

  previous = () => {
    this.sliderRef.current.previous();
    this.setState({ time: 0 });
  };

  render() {
    const { elements, thumbnailCarousel, slideTime } = this.props;
    const { currentIndex, time } = this.state;

    const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

    return (
      <div>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            {thumbnailCarousel ? (
              <ThumbnailCarousel
                activeThumbnail={currentIndex}
                onClick={(i: number) => this.goToIndex(i)}
                thumbnails={
                  elements &&
                  elements.map(({ image, thumbnailText }) => ({
                    image,
                    text: thumbnailText,
                  }))
                }
              />
            ) : (
              <div className={s.thumbnailsWrapper}>
                {elements &&
                  elements.map(({ thumbnail, thumbnailActive }, i) => (
                    <button
                      className={s.thumbnail}
                      key={i}
                      onClick={() => {
                        this.goToIndex(i);
                      }}
                    >
                      {i === currentIndex && thumbnailActive ? (
                        <GatsbyImage
                          className={s.thumbnailImage}
                          fluid={
                            thumbnailActive &&
                            thumbnailActive.localFile.childImageSharp.fluid
                          }
                        />
                      ) : (
                        <GatsbyImage
                          className={s.thumbnailImage}
                          fluid={thumbnail.localFile.childImageSharp.fluid}
                        />
                      )}
                    </button>
                  ))}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className={s.slider}>
              <a className={s.sliderWrapper} href={elements[currentIndex].link}>
                <BasicSlider
                  ref={this.sliderRef as any}
                  height="full"
                  slides={
                    elements &&
                    elements.map((brand, i) => ({ id: i, ...brand }))
                  }
                  renderSlide={this.renderSlide}
                  onSlideChange={this.onSlideChange}
                />
              </a>
            </div>
            {this.sliderRef && (
              <div className={s.controls}>
                {/* Mobile controls */}
                <div
                  className={`${s.controlsMobile} d-md-none d-flex flex-row align-items-center`}
                >
                  <button className={s.left} onClick={this.previous}>
                    <Icon
                      icon="arrow_backward"
                      color={isMobile ? 'white' : 'black'}
                      size={23}
                    />
                  </button>
                  <div className={s.title}>
                    <Button
                      slim
                      small
                      className={s.buttonLearnMore}
                      href={elements[currentIndex].link}
                    >
                      {elements[currentIndex].title}
                    </Button>
                    <Button
                      slim
                      small
                      dark
                      className={s.buttonLearnMore}
                      href={elements[currentIndex].link}
                    >
                      Learn more
                    </Button>
                  </div>
                  <button className={s.right} onClick={this.next}>
                    <Icon
                      icon="arrow_forward"
                      color={isMobile ? 'white' : 'black'}
                      size={23}
                    />
                  </button>
                </div>
                {/* Desktop controls */}
                <div className={`${s.title} d-none d-md-block`}>
                  <Button
                    slim
                    small
                    black
                    className={s.buttonLearnMore}
                    href={elements[currentIndex].link}
                  >
                    {elements[currentIndex].title}
                  </Button>
                  <Button
                    slim
                    small
                    dark
                    className={s.buttonLearnMore}
                    href={elements[currentIndex].link}
                  >
                    Learn more
                  </Button>
                </div>
                <div className={s.time}>
                  <Progress
                    percentage={(100 / slideTime) * time}
                    reset={!time}
                  />

                  <span className={s.seconds}>
                    00:{time < 10 ? `0${time}` : time}
                  </span>
                </div>
                <div className="d-none d-md-block">
                  <button className={s.left} onClick={this.previous}>
                    <Icon
                      icon="arrow_backward"
                      color={isMobile ? 'white' : 'black'}
                      size={23}
                    />
                  </button>
                  <button className={s.right} onClick={this.next}>
                    <Icon
                      icon="arrow_forward"
                      color={isMobile ? 'white' : 'black'}
                      size={23}
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
