import * as React from 'react';
import Block, { Props as BlockProps } from '../components/blocks/Block';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import { graphql, navigate } from 'gatsby';
import { Image, SEO, Newsposts } from '../types';
import LinkGroup from '../components/shared/LinkGroup';
import Section from '../components/shared/Section';
import * as s from './PageTemplate.module.scss';
import Button from '../components/swace/button';
import Reveal from '../components/swace/reveal';

interface SiteMeta {
  title: string;
  description: string;
  url: string;
}

export interface Page {
  title: string;
  id: string;
  wordpressId: number;
  wordpressParent: number;
  uri: string;
  pageFields: {
    disableBackground?: boolean;
    disableNavigation?: boolean;
    disableSubpageNavigation?: boolean;
    page: BlockProps[];
  };
  seo: SEO;
}

interface SiblingData {
  wordpressId: number;
  uri: string;
  title: string;
}

interface WpFormsData {
  uri: string;
  childrenWpForms: {
    page_heading: string;
    page_subheading: string;
    grid_frame_heading: string;
    grid_icon: Image;
  };
}

interface Props {
  page: Page;
  parent: SiblingData;
  siblings: {
    edges: Array<{
      node: SiblingData;
    }>;
  };
  children: {
    edges: Array<{
      node: SiblingData;
    }>;
  };
  wpFormsChildren: {
    edges: Array<{
      node: WpFormsData;
    }>;
  };
  news: any;
  offices: any;
  jobs: any;
  employees: any;
  departments: any;
  // instaPosts: any;
  newsposts: Newsposts;
  siteMeta: SiteMeta;
}

export const PageTemplate = ({
  page,
  parent,
  siblings,
  children,
  wpFormsChildren,
  news,
  employees,
  // instaPosts,
  offices,
  jobs,
  departments,
  newsposts,
  siteMeta,
}: Props) => {
  const blocks = page && page.pageFields.page;

  const links =
    !page.pageFields.disableSubpageNavigation &&
    page.wordpressParent &&
    page.wordpressParent !== 0
      ? [{ node: parent }, ...siblings.edges]
      : children && children.edges && children.edges.length > 0
      ? [
          {
            node: {
              wordpressId: page.id,
              uri: page.uri,
              title: page.title,
            },
          },
          ...children.edges,
        ]
      : null;

  const wpFormBlocks =
    blocks &&
    blocks.length > 0 &&
    blocks.filter(
      (block) =>
        block &&
        block.fieldGroupName &&
        block.fieldGroupName.endsWith('WpForms')
    );

  const wpFormsNode = [];
  const isWpForms = wpFormBlocks && wpFormBlocks.length > 0;

  if (
    isWpForms &&
    wpFormsChildren &&
    wpFormsChildren.edges &&
    wpFormsChildren.edges.length > 0
  ) {
    wpFormsChildren.edges.map((item) =>
      wpFormsNode.push({
        node: {
          path: item.node.uri,
          pageHeading: item.node.childrenWpForms.page[0].pageHeading,
          pageSubHeading: item.node.childrenWpForms.page[0].pageSubheading,
          gridFrameHeading: item.node.childrenWpForms.page[0].gridFrameHeading,
          gridIcon: item.node.childrenWpForms.page[0].gridIcon,
        },
      })
    );
  }
  if (!blocks) {
    return null;
  }

  const heroBlock =
    blocks &&
    blocks.length > 0 &&
    blocks[0].fieldGroupName &&
    blocks[0].fieldGroupName.endsWith('Hero')
      ? blocks[0]
      : null;
  const tailBlocks = heroBlock ? blocks.slice(1) : blocks;

  const activePageIndex =
    links &&
    page.id &&
    links.findIndex((e) => e.node.wordpressId === page.wordpressId);
  const nextPage =
    links &&
    (activePageIndex || activePageIndex === 0) &&
    links[(activePageIndex + 1) % links.length].node;

  return (
    <Layout
      seo={page.seo}
      siteMeta={siteMeta}
      disableBackground={page.pageFields.disableBackground}
      disableNavigation={page.pageFields.disableNavigation}
    >
      {heroBlock && <Block {...heroBlock} _sitemeta={siteMeta} />}
      {!page.pageFields.disableSubpageNavigation &&
        !isWpForms &&
        links &&
        links.length > 0 && (
          <div className={s.linksSectionWrapper}>
            <Section slim>
              <div className={`row`}>
                <div className={`col-12 col-md-10 offset-md-1 ${s.overflow}`}>
                  <div className={s.linksWrapper}>
                    <LinkGroup
                      buttons={links.map((e) => ({
                        buttonText: e.node.title,
                        buttonFunction: () => navigate(e.node.uri),
                        buttonId: e.node.wordpressId.toString(),
                      }))}
                      slimBottom
                      activeId={links[
                        activePageIndex
                      ].node.wordpressId.toString()}
                    />
                  </div>
                </div>
              </div>
            </Section>
          </div>
        )}

      {
        /* For all pages */
        tailBlocks &&
          tailBlocks.map((block, idx) => (
            <Block
              {...block}
              _blocks={blocks}
              _news={news}
              _jobs={jobs}
              _employees={employees}
              _departments={departments}
              // _instaPosts={instaPosts}
              _newsposts={newsposts}
              _offices={offices}
              _sitemeta={siteMeta}
              _pageTitle={page.title}
              _wpFormsChildren={wpFormsNode}
              key={block.id}
            />
          ))
      }

      {/* For pages with parents */}
      {!page.pageFields.disableSubpageNavigation && nextPage && !isWpForms && (
        <Section slimTop>
          <div className="d-flex justify-content-center">
            <Reveal lift>
              <Button
                onClick={() => {
                  navigate(nextPage.uri);
                }}
                dark
              >
                <span className={s.blackText}>Next:</span>{' '}
                <span
                  dangerouslySetInnerHTML={{ __html: nextPage.title || '' }}
                />
              </Button>
            </Reveal>
          </div>
        </Section>
      )}
      {!page.pageFields.disableNavigation && <Footer />}
    </Layout>
  );
};
export const query = graphql`
  fragment PageFragment on WpPage {
    title
    id
    wordpressId: id
    wordpressParent: parentId
    uri
    pageFields {
      disableBackground
      disableCookieNotice
      disableNavigation
      disableSubpageNavigation
      page {
        ...HeroBlockFragment
        ...BrandsBlockFragment
        ...NewsBlockFragment
        ...TextAndImageBlockFragment
        ...FourImagesGridBlockFragment
        ...PositionsBlockFragment
        ...EmployeesBlockFragment
        ...TimelineBlockFragment
        ...CitationBlockFragment
        ...SliderBlockFragment
        ...OfficesBlockFragment
        ...VisitBlockFragment
        ...ConnectBlockFragment
        ...WpFormsBlockFragment
        ...ContactFormBlockFragment
        ...ThreeColumnGridBlockFragment
        ...VimeoBlockFragment
        ...PortfoliosBlockFragment
        ...FullScreenImageBlockFragment
        ...NewspostsFragment
        ...FreeTextBlockFragment
        ...NewsletterformBlockFragment
        ...WTRegistrationPopupFragment
      }
    }
    seo {
      title
      canonical
      metaDesc
      opengraphImage {
        sourceUrl
      }
    }
  }
  fragment WordpressPageFragment on Query {
    allWpNewsItem(
      filter: { title: { ne: "SWACE_PLACEHOLDER" } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          newsItem {
            title
            newsCategory
            previewImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpNewspost(
      filter: { title: { ne: "SWACE_PLACEHOLDER" } }
      sort: { fields: newsPost___publishDate, order: DESC }
    ) {
      edges {
        node {
          id
          uri
          newsPost {
            title
            publishDate
            images {
              createDate
              description
              photographer
              title
              url
            }
          }
        }
      }
    }
    allWpOffice(filter: { title: { ne: "SWACE_PLACEHOLDER" } }) {
      edges {
        node {
          id
          uri
          slug
          title
          office {
            address {
              address
              country
              eMail
              name
              phoneNumber
              postNumberAndCity
            }
            cityDescription
            cityName
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            imagePositioning {
              vertical: verticalAdjustment
              horizontal: horizontalAdjustment
            }
          }
        }
      }
    }
    allWpPerson(filter: { title: { ne: "SWACE_PLACEHOLDER" } }) {
      edges {
        node {
          id
          uri
          link
          person {
            name
            title
            location
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  fragment WordpressSiteMetadataFragment on Query {
    wp {
      generalSettings {
        title
        url
        description
      }
    }
    ...TeamtailorBlockFragment
    ...TeamtailorDepartmentFragment
  }
`;

export default PageTemplate;
