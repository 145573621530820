import { graphql } from 'gatsby';
import * as React from 'react';
import Section from '../shared/Section';
import * as s from './Vimeo.module.scss';

export interface Props {
  id: number;
  vimeoSource: string;
  removeSectionPadding: boolean;
  src?: string;
  embedLink?: string;
  internal: {
    type: string;
  };
}

const vimeoRegex = /player\.vimeo\.com\/video\/([0-9]*)/;
const liveStreamRegex =
  /livestream\.com\/accounts\/\d{8}\/events\/\d{7}\/player/;
class Vimeo extends React.Component<Props> {
  formatVideoSrc = (video) => {
    const vimeoResult = video.match(vimeoRegex);
    const liveStreamResult = video.match(liveStreamRegex);

    let videoUrl;
    if (vimeoResult) {
      videoUrl = 'https://' + vimeoResult[0];
      const hParam = video.match(/h=[\D\d]{10}/);
      if (hParam) {
        videoUrl = videoUrl + '?';
        videoUrl = videoUrl + hParam[0];
      }
    } else if (liveStreamResult) {
      const paramStrings = video.split('?');

      videoUrl = 'https://' + liveStreamResult[0];
      if (paramStrings) {
        const params = video
          .split('?')
          [paramStrings.length - 1].replace(/(amp;|<\/a>)/g, '');
        videoUrl = `${videoUrl}?${params}`;
      }
    }

    return videoUrl;
  };
  render() {
    const { vimeoSource, src, embedLink, removeSectionPadding } = this.props;

    const vimeoSrc =
      vimeoSource === 'formatted' ? this.formatVideoSrc(embedLink) : src;

    if (removeSectionPadding) {
      return (
        <div className={s.iframeWrapper}>
          <iframe
            src={vimeoSrc}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            className={s.window}
          />
        </div>
      );
    }

    return (
      <Section slim sectionName="Vimeo">
        <div className={s.embeddedVideoWrapper}>
          <iframe
            width="1920"
            height="1080"
            src={vimeoSrc}
            frameBorder="0"
            allow="autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Section>
    );
  }
}

export const query = graphql`
  fragment VimeoBlockFragment on WpPage_Pagefields_Page_Vimeo {
    ... on WpPage_Pagefields_Page_Vimeo {
      fieldGroupName
      vimeoSource
      removeSectionPadding
      src
      embedLink
    }
  }
`;

export default Vimeo;
