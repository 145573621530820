import React from 'react';
import { graphql } from 'gatsby';
import Text from '../../components/shared/Text';
import Section from '../../components/shared/Section';
import * as s from './Offices.module.scss';
import BackgroundImage from '../swace/gatsby-background-image';
import { navigate } from '@reach/router';

export interface Props {
  id: number;
  title: string;
  ingress: string;
  _offices;
}
// filter(e=> e.node.slug !== "swace_office_placeholder").
class Offices extends React.Component<Props> {
  render() {
    const { title, ingress, _offices } = this.props;
    return (
      <div>
        <Section>
          <div className="row">
            <div className="col col-md-10 offset-md-1">
              <Text h2>{title}</Text>
              <Text ingress>{ingress}</Text>
            </div>
          </div>
        </Section>
        <Section wide slim>
          {_offices.edges
            .filter((e) => e.node.slug !== 'swace_office_placeholder')
            .map((e) => (
              <div
                className={s.cityImageWrapper}
                onClick={() => navigate(e.node.uri)}
                key={e.node.title}
              >
                <BackgroundImage
                  image={e.node.office.image}
                  className={s.cityImage}
                  imagePositioning={e.node.office.imagePositioning}
                >
                  <div className={s.overlay}>
                    <div className="container h-100">
                      <div className="row h-100">
                        <div className="col col-md-10 offset-md-1 h-100">
                          <div className={s.textWrapper}>
                            <Text h2 light>
                              {e.node.title}
                            </Text>
                            <div className={s.cityAddress}>
                              <Text p light>
                                {e.node.office.address.name} <br />
                                {e.node.office.address.address},{' '}
                                {e.node.office.address.post_number_and_city}
                              </Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </BackgroundImage>
              </div>
            ))}
        </Section>
      </div>
    );
  }
}

export const query = graphql`
  fragment OfficesBlockFragment on WpPage_Pagefields_Page_Offices {
    ... on WpPage_Pagefields_Page_Offices {
      fieldGroupName
      title
      ingress
    }
  }
`;

export default Offices;
