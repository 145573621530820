import * as React from 'react';
import { graphql } from 'gatsby';
import * as s from './ContactForm.module.scss';
import Section from '../shared/Section';
import Button from '../swace/button';
import Text from '../shared/Text';
import InputField from '../shared/ApplyForm/InputField';
import { ApiClient } from '../../libs/api/ApiClient';

export interface Props {
  heading: string;
  targetEMails: [{ name: string; eMail: string }];
  information: string;
}

interface State {
  firstName: string;
  lastName: string;
  email: string;
  targetEmail: string;
  phoneNumber: string;
  message: string;
  isTextAreaFocused: boolean;
  formSuccess: boolean;
}

const emptyForm = {
  firstName: '',
  lastName: '',
  email: '',
  targetEmail: '',
  phoneNumber: '',
  message: '',
  isTextAreaFocused: false,
  formSuccess: null,
};

class ContactForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...emptyForm };
  }

  client = new ApiClient();

  private selectRef = React.createRef<any>();

  componentDidMount() {
    const select = this.selectRef.current;
    if (select) {
      const defaultTargetEmail = select && select.selectedOptions[0].value;
      select.selectedOptions[0].selected = true;
      this.setSelect(defaultTargetEmail);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    formData.append('target_email', this.state.targetEmail);

    this.sendForm(formData);
  };

  async sendForm(data) {
    const response = await this.client.sendContactForm(data);
    if (response.ok) {
      this.setState({ ...emptyForm, formSuccess: true });
      setTimeout(
        function () {
          this.setState({
            formSuccess: null,
          });
        }.bind(this),
        4000
      );
    } else {
      this.setState({
        formSuccess: false,
      });
      setTimeout(
        function () {
          this.setState({ formSuccess: null });
        }.bind(this),
        4000
      );
    }
  }
  setSelect = (e) => {
    if (typeof e == 'string') {
      this.setState({
        targetEmail: e,
      });
    } else {
      this.setState({
        targetEmail: this.selectRef.current.value,
      });
    }
  };

  render() {
    const { heading, targetEMails, information } = this.props;
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      message,
      isTextAreaFocused,
      formSuccess,
    } = this.state;

    return (
      <Section>
        <div className="row">
          <div className="col-md-10 col-xl-7 offset-md-1 offset-xl-1">
            <Text h2>{heading}</Text>
            <form className={s.form} onSubmit={this.onSubmit}>
              <select onChange={this.setSelect} ref={this.selectRef}>
                {targetEMails &&
                  targetEMails.map((object, i) => (
                    <option key={i} value={object.eMail}>
                      {object.name}
                    </option>
                  ))}
              </select>
              <div className={`row d-flex ${s.contentWrapper}`}>
                <InputField
                  backgroundWhite={true}
                  type="text"
                  name="first_name"
                  label="First name"
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                  value={firstName}
                  required
                />
                <InputField
                  backgroundWhite={true}
                  type="text"
                  name="last_name"
                  label="Last name"
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                  value={lastName}
                  required
                />
                <InputField
                  backgroundWhite={true}
                  type="email"
                  name="email"
                  label="E-mail"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  value={email}
                  required
                />
                <InputField
                  backgroundWhite={true}
                  type="text"
                  name="phone_number"
                  label="Phone number +46 ..."
                  pattern="^(\+)([0-9]{2})(\d+)$"
                  onChange={(e) =>
                    this.setState({ phoneNumber: e.target.value })
                  }
                  value={phoneNumber}
                  required
                />
                <div className="col-12">
                  <div
                    className={`${s.placeholder} ${
                      isTextAreaFocused || message.length > 0 ? s.focus : ''
                    }`}
                  >
                    {`${
                      isTextAreaFocused || message.length > 0 ? 'Message' : ''
                    }`}
                  </div>
                  <textarea
                    className={`col-12 ${s.input} ${
                      isTextAreaFocused || message.length > 0 ? s.focus : ''
                    }`}
                    name="message"
                    placeholder="Message"
                    onChange={(e) => this.setState({ message: e.target.value })}
                    value={message}
                    onFocus={() => this.setState({ isTextAreaFocused: true })}
                    onBlur={() => this.setState({ isTextAreaFocused: false })}
                    maxLength={5000}
                    required
                  />
                  <div className={s.textcount}>{message.length}/5000</div>
                </div>
                {formSuccess != null && (
                  <div className={`col-12 col-md-6 ${s.statusWrapper}`}>
                    <div
                      className={`col-12 ${s.status} ${
                        formSuccess ? s.success : s.error
                      }`}
                    >
                      {formSuccess ? (
                        <p>Your message has been received!</p>
                      ) : (
                        <p>Yikes, something went wrong!</p>
                      )}
                    </div>
                  </div>
                )}
                {formSuccess == null && (
                  <Button wide filled onClick={() => {}} type="submit">
                    <span>Send</span>
                  </Button>
                )}
              </div>
            </form>
            <div
              className={s.information}
              dangerouslySetInnerHTML={{ __html: information }}
            />
          </div>
        </div>
      </Section>
    );
  }
}

export const query = graphql`
  fragment ContactFormBlockFragment on WpPage_Pagefields_Page_ContactForm {
    ... on WpPage_Pagefields_Page_ContactForm {
      fieldGroupName
      heading
      targetEMails {
        name
        eMail
      }
      information
    }
  }
`;

export default ContactForm;
