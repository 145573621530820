import * as React from 'react';
import * as s from './WpForm.module.scss';
import { graphql } from 'gatsby';
import { Image } from '../../types';
import Text from '../shared/Text';
import Section from '../shared/Section';
import WpFormGridItem from '../shared/WpFormGridItem';
import WpFormIframe from './WpFormIframe';

export interface Props {
  uri: string;
  pageLink: {
    uri: string;
  };
  pageHeadingCurrPage: string;
  pageSubHeadingCurrPage: string;
  _sitemeta: Array<{
    url: string;
  }>;
  _wpFormsChildren: Array<{
    node: {
      path: string;
      pageHeading: string;
      pageSubHeading: string;
      gridFrameHeading: string;
      gridIcon: Image;
    };
  }>;
}

class WpForm extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      pageLink,
      pageHeadingCurrPage,
      pageSubHeadingCurrPage,
      _wpFormsChildren,
      _sitemeta,
    } = this.props;
    const backendUrl = _sitemeta['url'];

    const hasChildren = _wpFormsChildren && _wpFormsChildren.length > 0;

    const childGrid =
      hasChildren &&
      _wpFormsChildren.map((item) => (
        <div key={item.node.path} className="col-md-6">
          <WpFormGridItem
            link={item.node.path}
            image={item.node.gridIcon}
            heading={
              item.node.gridFrameHeading
                ? item.node.gridFrameHeading
                : item.node.pageHeading
            }
            subHeading={item.node.pageSubHeading}
          />
        </div>
      ));
    return (
      <Section>
        <div className={hasChildren ? '' : s.iframeContainer}>
          <Text h1>{pageHeadingCurrPage}</Text>
          <Text h6>{pageSubHeadingCurrPage}</Text>
          <div className={hasChildren ? s.container : ''}>
            <div className="row d-flex align-items-center">
              {hasChildren ? (
                childGrid
              ) : (
                <WpFormIframe
                  backendUrl={backendUrl}
                  link={pageLink && pageLink.uri}
                />
              )}
            </div>
          </div>
        </div>
      </Section>
    );
  }
}

export const query = graphql`
  fragment WpFormsBlockFragment on WpPage_Pagefields_Page_WpForms {
    ... on WpPage_Pagefields_Page_WpForms {
      fieldGroupName
      pageLink {
        ... on WpPage {
          uri
        }
      }
      pageHeadingCurrPage: pageHeading
      pageSubHeadingCurrPage: pageSubheading
    }
  }
`;

export default WpForm;
