import * as React from 'react';
import { Image } from '../../types';
import Section from '../shared/Section';
import { graphql } from 'gatsby';
import { ScrollSlider } from '../shared/slider';

interface Slide {
  fieldGroupName: string;
  image: Image;
  contentImage: Image;
  text: string;
  title: string;
}

export interface Props {
  fieldGroupName: string;
  slides: Slide[];
  color: string;
  _blocks?;
}

class Slider extends React.Component<Props> {
  render() {
    const { fieldGroupName, slides, _blocks, color } = this.props;

    const blockIndex = _blocks.findIndex(
      (b) => b.fieldGroupName === fieldGroupName
    );

    const slimTop =
      blockIndex > 0 &&
      (_blocks[blockIndex - 1].fieldGroupName.endsWith('Timeline') ||
        _blocks[blockIndex - 1].fieldGroupName.endsWith('Visit'));
    const slimBottom =
      _blocks.length - 2 >= blockIndex &&
      (_blocks[blockIndex + 1].fieldGroupName.endsWith('Timeline') ||
        _blocks[blockIndex + 1].fieldGroupName.endsWith('Visit'));

    return (
      <Section wide slimTop={slimTop} slimBottom={slimBottom}>
        <ScrollSlider slides={slides} color={color} />
      </Section>
    );
  }
}

export const query = graphql`
  fragment SliderBlockFragment on WpPage_Pagefields_Page_Slider {
    ... on WpPage_Pagefields_Page_Slider {
      fieldGroupName
      color
      slides {
        fieldGroupName
        title
        text
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        contentImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;

export default Slider;
