import * as React from 'react';
import { graphql } from 'gatsby';
import * as s from './ThreeColumnGrid.module.scss';
import Reveal from '~components/swace/reveal';
import BackgroundImage from '~components/swace/gatsby-background-image';
import { Image } from '~types';
import plusSvg from '../../img/Plus.svg';
import Section from '~components/shared/Section';
import Text from '~components/shared/Text';
import { DynamicLink } from '../shared/components';

export interface Props {
  id: number;
  items: [
    {
      label: string;
      title: string;
      text: string;
      image: Image;
      pageLink: {
        uri: string;
      };
    }
  ];
}

class ThreeColumnGrid extends React.Component<Props> {
  getBackgroundImage = (hasLink: boolean, image: Image, index: number) => (
    <div
      key={index}
      className={`${s.imageWrapper} ${hasLink ? 'position-relative' : ''}`}
    >
      <BackgroundImage image={image}>
        <div className={s.image} />
      </BackgroundImage>
      {hasLink && (
        <div className={s.newsCornerWrapper}>
          <Reveal lift>
            <>
              <div className={s.newsCorner} />
              <img src={plusSvg} className={s.plus} />
            </>
          </Reveal>
        </div>
      )}
    </div>
  );
  render() {
    const { items } = this.props;
    return (
      <Section slimBottom slimTop sectionName="ThreeColumn">
        <Reveal lift>
          <div className="row">
            {items.map((item, i) => {
              const textcontent = (
                <div className={s.textWrapper}>
                  <div className={s.titleWrapper}>
                    {item.label && (
                      <Text label teal>
                        {item.label}
                      </Text>
                    )}
                    <Text ingress>{item.title}</Text>
                  </div>
                  <div>
                    <Text pMedium>{item.text}</Text>
                  </div>
                </div>
              );

              if (item.pageLink) {
                return (
                  <div key={i} className={`col-12 col-sm-4 ${s.colWrapper}`}>
                    <DynamicLink
                      to={item.pageLink.uri}
                      className={`${s.linkWrapper}`}
                    >
                      {this.getBackgroundImage(true, item.image, i)}
                      {textcontent}
                    </DynamicLink>
                  </div>
                );
              }
              return (
                <div key={i} className={`col-12 col-sm-4 ${s.colWrapper}`}>
                  {this.getBackgroundImage(false, item.image, i)}
                  {textcontent}
                </div>
              );
            })}
          </div>
        </Reveal>
      </Section>
    );
  }
}

export const query = graphql`
  fragment ThreeColumnGridBlockFragment on WpPage_Pagefields_Page_ThreeColumnGrid {
    ... on WpPage_Pagefields_Page_ThreeColumnGrid {
      fieldGroupName
      items {
        label
        title
        text
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        pageLink {
          ... on WpPage {
            uri
          }
        }
      }
    }
  }
`;

export default ThreeColumnGrid;
