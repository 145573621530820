import * as React from 'react';
import { graphql } from 'gatsby';
import { Image } from '../../types';
import * as s from './FourImagesGrid.module.scss';
import Section from '../shared/Section';
import Text from '../shared/Text';
import BackgroundImage from '../swace/gatsby-background-image';
import Reveal from '../swace/reveal';
import LinkGroup from '../shared/LinkGroup';
import {
  Vertical,
  Horizontal,
} from '../swace/gatsby-background-image/GatsbyBackgroundImage';

export interface Props {
  id: number;
  title: string;
  text: string;
  buttons: Array<{
    buttonText: string;
    buttonLink: {
      uri: string;
    };
  }>;
  images: Array<{
    image: Image;
    imagePositioning: {
      vertical: Vertical;
      horizontal: Horizontal;
    };
  }>;
  internal: {
    type: string;
  };
}

class FourImagesGrid extends React.Component<Props> {
  render() {
    const { title, text, buttons, images } = this.props;

    return (
      <React.Fragment>
        <Section slimBottom>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <Reveal drop>
                <Text h2>{title}</Text>
              </Reveal>
              <Reveal lift>
                <Text ingress>{text}</Text>
              </Reveal>
              <Reveal lift>
                <LinkGroup thickBottom buttons={buttons} />
              </Reveal>
            </div>
          </div>
        </Section>
        <Section wide slimTop slimBottom>
          <Reveal lift>
            <div className="row">
              {images.map((image, i) => (
                <div key={i} className={`col-12 col-sm-6 ${s.imageWrapper}`}>
                  <BackgroundImage
                    image={image.image}
                    imagePositioning={image.imagePositioning}
                  >
                    <div className={s.image} />
                  </BackgroundImage>
                </div>
              ))}
            </div>
          </Reveal>
        </Section>
      </React.Fragment>
    );
  }
}

export const query = graphql`
  fragment FourImagesGridBlockFragment on WpPage_Pagefields_Page_FourImagesGrid {
    ... on WpPage_Pagefields_Page_FourImagesGrid {
      fieldGroupName
      title
      text
      buttons {
        buttonText
        buttonLink {
          ... on WpPage {
            uri
          }
        }
      }
      images {
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        imagePositioning {
          vertical: verticalAdjustment
          horizontal: horizontalAdjustment
        }
      }
    }
  }
`;

export default FourImagesGrid;
