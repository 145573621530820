// extracted by mini-css-extract-plugin
export var afterCurrent = "ArrowSlider-module--afterCurrent--xw7vy";
export var beforeCurrent = "ArrowSlider-module--beforeCurrent--Fpc2x";
export var buttonLearnMore = "ArrowSlider-module--buttonLearnMore--ZJzEn";
export var controls = "ArrowSlider-module--controls--ivF-Y";
export var controlsMobile = "ArrowSlider-module--controlsMobile--lR8Bn";
export var current = "ArrowSlider-module--current--uDL45";
export var left = "ArrowSlider-module--left--lcHNd";
export var name = "ArrowSlider-module--name--9KbXB";
export var right = "ArrowSlider-module--right--WL+Gf";
export var seconds = "ArrowSlider-module--seconds--jqEXs";
export var slide = "ArrowSlider-module--slide--CSEhm";
export var slideText = "ArrowSlider-module--slideText--t2Lvf";
export var slider = "ArrowSlider-module--slider--xzQjx";
export var sliderWrapper = "ArrowSlider-module--sliderWrapper--InlER";
export var thumbnail = "ArrowSlider-module--thumbnail--JpS4r";
export var thumbnailImage = "ArrowSlider-module--thumbnailImage--ZcaWe";
export var thumbnails = "ArrowSlider-module--thumbnails--YEibJ";
export var thumbnailsWrapper = "ArrowSlider-module--thumbnailsWrapper--og8+M";
export var time = "ArrowSlider-module--time--HVGl4";
export var title = "ArrowSlider-module--title--0tgt1";